import React from 'react';

import SideBar from "./SideBar";



const Home = () => {

    // const { userLoggedIn } = useAuth();
    //
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    //
    // const [isSigningIn, setIsSigningIn] = useState(false);
    //
    // const onSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!isSigningIn){
    //         setIsSigningIn(true);
    //         await signInWithEmailAndPassword(email, password);
    //     }
    // }

    return (
        <div className="bg-bgColor flex h-full w-full mt-16">

            <SideBar/>

            Home

        </div>
    )
};


export default Home;
