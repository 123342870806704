import React, {useEffect, useState} from 'react';
import SideBar from "./SideBar";
import {Button, Input, Select} from "@headlessui/react";
import {
    ArrowDownTrayIcon,
    ArrowPathIcon,
    MagnifyingGlassIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import CustomerCard from "./Customers/CustomerCard";
import OrderCard from "./Orders/OrderCard";

const Orders = () => {
    const { currentUser } = useAuth();

    const [status, setStatus] = useState("A");
    const [existing, setExisting] = useState(false);

    const [selectedIndustry, setSelectedIndustry] = useState("");

    const [orders, setOrders] = useState([]);
    const [ordersPending, setOrdersPending] = useState([]);

    const [filteredPendingOrders, setFilteredPendingOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    // Handle dropdown change
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);

                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };


        const fetchOrdersPending = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`pendingOrders_${currentUser.uid}`);
                    if (storedPendingData) {
                        const pendingData = JSON.parse(storedPendingData);
                        setOrdersPending(pendingData);
                        setFilteredPendingOrders(pendingData);
                    } else {
                        const pendingOrders = db.collection("Orders").doc(currentUser.uid).collection("Pending");
                        const snapshot = await pendingOrders.get();

                        if (!snapshot.empty) {
                            const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setOrdersPending(pendingData);
                            setFilteredPendingOrders(pendingData);
                            localStorage.setItem(`pendingOrders_${currentUser.uid}`, JSON.stringify(pendingData));
                        } else {
                            console.log('Order document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching orders data:', error);
                }
            }
        }

        const fetchOrdersActive = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeOrders_${currentUser.uid}`);
                    if (storedActiveData) {
                        const customersData = JSON.parse(storedActiveData);
                        setOrders(customersData);
                        setFilteredOrders(customersData);
                    } else {
                        const activeOrders = db.collection("Orders").doc(currentUser.uid).collection("Active");
                        const snapshot = await activeOrders.get();

                        if (!snapshot.empty) {
                            const activeData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setOrders(activeData);
                            setFilteredOrders(activeOrders);
                            localStorage.setItem(`activeOrders_${currentUser.uid}`, JSON.stringify(activeData));
                        } else {
                            console.log('Orders document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching orders data:', error);
                }
            }
        }



        fetchOrdersPending();
        fetchOrdersActive();
        fetchUserName();
    }, [currentUser]);



    const onUpdate = async () => {
        try {
            const pendingCustomers = db.collection("Orders").doc(currentUser.uid).collection("Pending");
            const snapshot = await pendingCustomers.get();

            if (!snapshot.empty) {
                const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setOrdersPending(pendingData);
                setFilteredPendingOrders(pendingData);
                localStorage.setItem(`pendingOrders_${currentUser.uid}`, JSON.stringify(pendingData));
            } else {
                console.log('Customer document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }

        try {
            const customers = db.collection("Orders").doc(currentUser.uid).collection("Active");
            const snapshot = await customers.get();

            if (!snapshot.empty) {
                const activeData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setOrders(activeData);
                setFilteredOrders(activeData);
                localStorage.setItem(`activeOrders_${currentUser.uid}`, JSON.stringify(activeData));
            } else {
                console.log('Order document not found');
            }

        } catch (error) {
            console.error("Error updating Orders", error);
        }
    };


    const handlePendingSearch = (query) => {
        setSearchQuery(query);
        const filtered = ordersPending.filter((customer) =>
            customer.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredPendingOrders(filtered);
    }

    const handleActiveSearch = (query) => {
        setSearchQuery(query);
        const filtered = orders.filter((order) =>
            order.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredOrders(filtered);
    }


    // Function to toggle visibility
    const toggleVisibility = () => {
        setExisting(!existing);
    };


    const filterCustomersByIndustry = (industry) => {
        setSelectedIndustry(industry);
        const filtered = orders.filter(customer => industry === '' || customer.industry === industry);
        setFilteredOrders(filtered);
    };

    const filterPendingCustomersByIndustry = (industry) => {
        setSelectedIndustry(industry);
        const filtered = ordersPending.filter(customer => industry === '' || customer.industry === industry);
        setFilteredPendingOrders(filtered);
    };

    const filtering = (e) => {
        if(status === "A"){
            filterCustomersByIndustry(e.target.value);
        }
        if (status === "P"){
            filterPendingCustomersByIndustry(e.target.value);
        }
    }

    // Function to calculate the total grand total from pending orders
    const calculateTotalGrandTotal = (orders) => {
        return orders.reduce((total, order) => {
            return total + (order.grandTotal || 0); // Add 0 for undefined grandTotal
        }, 0);
    };

    const totalGrandTotal = calculateTotalGrandTotal(ordersPending);

    return (
        <div className="bg-bgColor flex h-screen w-full mt-16 overflow-y-auto">

            <SideBar/>

            <div className="flex flex-col w-full mt-3 mb-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-1">
                    <div className="text-2xl w-1/6 text-black mr-5 flex flex-row">Orders
                        {/*Buttons Delete*/}
                        <div className="flex flex-col items-center ml-3 mt-auto mb-auto">
                            <Button className="rounded-full hover:bg-green-200 hover:animate-spin mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"
                                    onClick={onUpdate}
                            >
                                <ArrowPathIcon className="h-6 text-borderColor hover:text-bgDarkGreen m-auto"/>
                            </Button>
                        </div>
                    </div>

                    <div className="text-xl mr-1.5 text-black bg-white w-4/6 flex rounded-md border drop-shadow shadow-gray-900">

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="text-black font-semibold text-sm mr-3">Active Order Total:</div>
                            <div className="text-black text-sm rounded-md border px-2">{orders.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="text-black font-semibold text-sm mr-3">Pending Order Total:</div>
                            <div className="text-black text-sm rounded-md border px-2">$ {numberWithCommas(totalGrandTotal.toFixed(2))}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="text-black font-semibold text-sm mr-3">Filtered Order Total:</div>
                            {status === "A" && (
                                <div>

                                    <div className="text-black text-sm rounded-md border px-2">{filteredOrders.length}</div>

                                </div>
                            )}

                            {status === "P" && (
                                <div>

                                    <div className="text-black text-sm rounded-md border px-2">{filteredPendingOrders.length}</div>

                                </div>
                            )}
                        </div>

                    </div>

                    <div className="w-1/6 text-black ml-1.5">
                        <Button className="bg-bgDarkGreen h-full w-full hover:scale-105 hover:bg-opacity-80 rounded-md py-1.5 px-6 text-white text-sm mr-3 flex flex-row align-middle justify-center items-center"
                                onClick={toggleVisibility}
                        >
                            {/*<ArrowDownTrayIcon className="w-6"/>*/}
                            {/*<div className="w-full">Download Excel</div>*/}
                            <div className="w-full">Download as Excel</div>
                            <ArrowDownTrayIcon className="w-6"/>

                        </Button>
                    </div>


                </div>

                {/*Search bar row*/}
                <div className="w-full h-fit flex mb-3">

                    <div className="w-1/2 text-black mr-1.5 h-fit">
                        <div className="text-black text-sm mb-1">Customer</div>

                        <div className="bg-white w-full rounded-md border flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900">
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>


                            {status === "A" && (
                                <div className="w-full">

                                    <Input
                                        className="rounded-md p-1.5 w-full"
                                        placeholder="Search Active Customers by Name"
                                        value={searchQuery}
                                        onChange={(e) => handleActiveSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                            {status === "P" && (
                                <div className="w-full">

                                    <Input
                                        className="rounded-md p-1.5 w-full"
                                        placeholder="Search Pending Customers by Name"
                                        value={searchQuery}
                                        onChange={(e) => handlePendingSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                        </div>

                    </div>

                    <div className="w-1/6 text-black mr-1.5 ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            <div className="text-black text-sm mb-1">Status</div>
                            <Select className="border h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105"
                                    value={status}
                                    onChange={handleStatusChange}
                            >
                                <option value="A">Active</option>
                                <option value="P">Pending</option>
                            </Select>
                        </div>

                    </div>

                    <div className="w-1/6 text-black mr-1.5 ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            <div className="text-black text-sm mb-1">Industry</div>

                            <Select className="border drop-shadow h-full shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105"
                                // onChange={(e) => filterCustomersByIndustry(e.target.value)}
                                    onChange={filtering}
                            >
                                <option value="">Select...</option>
                                <option value="BOTANICAL">Botanical Garden</option>
                                <option value="DISTRIB">Product Distributor</option>
                                <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                <option value="FARM">Agriculture - Farm</option>
                                <option value="GOLF">Golf Course</option>
                                <option value="GOVERNMENT">Government Entity</option>
                                <option value="INDUSTRIAL">Industrial</option>
                                <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                <option value="MRSALE">One Time Purchase Expected</option>
                                <option value="MUNICIPAL">Municipal/ School</option>
                                <option value="Non-Profit">Non-Profit Account</option>
                                <option value="OTHER">Other</option>
                                <option value="SPORTFLD">Sportsfield</option>
                            </Select>
                        </div>

                    </div>

                    <div className="w-1/6 text-black ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            <div className="text-black text-sm mb-1">Pricing Type</div>
                            <Select className="border h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105">
                                <option value="">Select...</option>
                                <option value="AG">Agriculture</option>
                                <option value="DIST1">Distributor NE/INTL</option>
                                <option value="DIST2">Distributor SE/CAN</option>
                                <option value="NE">Northeast</option>
                                <option value="SE">Southeast</option>
                            </Select>
                        </div>

                    </div>


                </div>

                {/*Customer Body*/}
                <div className="w-full h-full rounded-md mb-3">

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-full flex-col">


                        <div className="w-full h-full bg-white rounded-lg">

                            {/*HEADER*/}
                            <div className="w-full flex flex-row pl-3 pr-3">

                                <div className="w-full flex flex-row border-b-2">

                                    {/*Buttons Delete*/}
                                    <div className="flex flex-col items-center mr-3">
                                        <div className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-white m-auto"/>
                                        </div>
                                    </div>

                                    {/*Contact Name*/}
                                    <div className="w-1/3 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Sales Rep</div>
                                        </div>

                                    </div>

                                    {/*Contact Phone*/}
                                    <div className="w-1/3 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Customer</div>
                                        </div>


                                    </div>


                                    {/*Status*/}
                                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">

                                        <div className="w-fit p-1 h-8 rounded-full flex flex-row">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Status</div>
                                        </div>

                                    </div>


                                    {/**/}
                                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                                        <div className="w-fit p-1 h-8 rounded-full">
                                            <div className="flex items-center justify-center h-full text-sm font-semibold">Grand Total</div>
                                        </div>


                                    </div>



                                    {/*Buttons DropDown*/}
                                    <div className="flex flex-col items-center">
                                        <div className=" mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-white m-auto"/>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            {/*CUSTOMERS MAP*/}
                            <div className="overflow-y-auto h-full bg-white p-1 rounded-lg max-h-[calc(75vh-2.85rem)] scroll">


                                {/*{status === "A" && (*/}
                                {/*    <div>*/}

                                {/*        {filteredCustomers.length === 0 ? (*/}
                                {/*            // Display custom dialog if sprays array is empty*/}
                                {/*            <div className="flex w-full h-full justify-center items-center py-10">*/}

                                {/*                <div*/}
                                {/*                    className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">*/}
                                {/*                    <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">*/}
                                {/*                        <p className="text-xl text-black">No Products in this Order</p>*/}
                                {/*                        <p className="text-lg text-black mt-2">Add a Product</p>*/}
                                {/*                        <p className="text-xs text-black">1] Search Product by name using the search bar above.</p>*/}
                                {/*                        <p className="text-xs text-black">2] Browse the Digital Catalog by clicking on the button above.</p>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}

                                {/*            </div>*/}
                                {/*        ) : (*/}
                                {/*            // Display SprayCard components if sprays array is not empty*/}
                                {/*            filteredCustomers.map((order, index) => {*/}
                                {/*                return (*/}
                                {/*                    <OrderCard*/}
                                {/*                        key={index}*/}
                                {/*                        index={index}*/}
                                {/*                        order={order}*/}
                                {/*                    />*/}
                                {/*                )*/}
                                {/*            })*/}
                                {/*        )}*/}

                                {/*    </div>*/}
                                {/*)}*/}

                                {status === "P" && (
                                    <div>

                                        {filteredPendingOrders.length === 0 ? (
                                            // Display custom dialog if sprays array is empty
                                            <div className="flex w-full h-full justify-center items-center py-10">

                                                <div
                                                    className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                                    <div
                                                        className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                        <p className="text-xl text-black">No Products in this Order</p>
                                                        <p className="text-lg text-black mt-2">Add a Product</p>
                                                        <p className="text-xs text-black">1] Search Product by name using
                                                            the search bar above.</p>
                                                        <p className="text-xs text-black">2] Browse the Digital Catalog by
                                                            clicking on the button above.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            // Display SprayCard components if sprays array is not empty
                                            filteredPendingOrders.map((order, index) => {
                                                return (
                                                    <OrderCard
                                                        key={index}
                                                        index={index}
                                                        order={order}
                                                    />
                                                )
                                            })
                                        )}

                                    </div>
                                )}

                            </div>


                        </div>




                    </div>

                </div>

                {/*Customers Footer [Buttons]*/}
                <div className="w-full h-fit flex mb-3 mt-3">

                </div>

            </div>


        </div>
    )
};


export default Orders;
