import {Button} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {
    ArrowDownCircleIcon,
    ArrowPathIcon,
    ArrowUpCircleIcon,
    CheckCircleIcon,
    TrashIcon
} from "@heroicons/react/24/outline";
import {useAuth} from "../../contexts/authContext";

const OrderCard = ({ order }) => {

    const [isVisible, setIsVisible] = useState(false); // State to track visibility
    const { currentUser } = useAuth();

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    // // Handle the remove action with animation
    // const handleRemove = () => {
    //     setIsRemoving(true); // Trigger the animation
    //     // setTimeout(() => onRemove(id), 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    // };

    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };


    // Function to move document from "pending" to "active" collection and update status
    // const moveToActive = async (customerId) => {
    //     try {
    //         // Reference to the customer document in the "pending" collection
    //         const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);
    //
    //         // Get the customer document from the "pending" collection
    //         const customerSnapshot = await getDoc(customerDocRef);
    //         if (!customerSnapshot.exists()) {
    //             throw new Error("Customer not found in the pending collection");
    //         }
    //
    //         // Get the customer data and set status to 'active'
    //         const customerData = customerSnapshot.data();
    //         const updatedCustomerData = { ...customerData, status: "active" };
    //
    //         // Reference to the destination document in the "clients" collection
    //         const activeDocRef = doc(db, `Customers/${currentUser.uid}/clients`, customerId);
    //
    //         // Copy document to the "clients" collection with updated status
    //         await setDoc(activeDocRef, updatedCustomerData);
    //
    //         // Delete document from the "pending" collection
    //         await deleteDoc(customerDocRef);
    //
    //         console.log("Customer moved to clients collection and status updated to active.");
    //         alert("Customer moved to clients collection and status updated to active.");
    //     } catch (error) {
    //         console.error("Error moving customer to clients collection:", error);
    //         alert("Error moving customer to clients collection.");
    //     }
    // };



    // const submitUpdate = async (customerId) => {
    //     try {
    //         // Reference to the customer document in the "pending" collection
    //         const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);
    //         const collectionRef = db.collection('Customers').doc(currentUser.uid).collection("pending");
    //
    //         // Get the customer document from the "pending" collection
    //         const customerSnapshot = await getDoc(customerDocRef);
    //         if (!customerSnapshot.exists()) {
    //             throw new Error("Customer not found in the pending collection");
    //         }
    //
    //         // Get the customer data and set status to 'active'
    //         const customerData = customerSnapshot.data();
    //         const updatedCustomerData = {
    //             ...customerData,
    //             customerCode: customerCode,
    //             type: type,
    //             creditLimit: creditLimit,
    //             priceType: priceCode,
    //         };
    //
    //         // Update the document directly with customerDocRef
    //         await collectionRef.doc(customerId).update(updatedCustomerData);
    //
    //         console.log("Customer successfully updated with internal setup.");
    //         alert("Customer successfully updated with internal setup.");
    //     } catch (error) {
    //         console.error("Customer update Failed.", error);
    //         alert("Customer update Failed.");
    //     }
    // };


    // const submitUpdate = async (customerId) => {
    //     try {
    //         // Reference to the customer document in the "pending" collection
    //         const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);
    //
    //         // Get the customer document from the "pending" collection
    //         const customerSnapshot = await getDoc(customerDocRef);
    //         if (!customerSnapshot.exists()) {
    //             throw new Error("Customer not found in the pending collection");
    //         }
    //
    //         // Get the customer data and set status to 'active'
    //         const customerData = customerSnapshot.data();
    //         const updatedCustomerData = {
    //             ...customerData,
    //             customerCode,
    //             type,
    //             creditLimit,
    //             priceType: priceCode,
    //         };
    //
    //         // Update the document directly with customerDocRef
    //         await updateDoc(customerDocRef, updatedCustomerData);
    //
    //         console.log("Customer successfully updated with internal setup.");
    //         alert("Customer successfully updated with internal setup.");
    //     } catch (error) {
    //         console.error("Customer update Failed.", error);
    //         alert("Customer update Failed.");
    //     }
    // };


    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className={`w-full flex flex-col border-b-2 p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : 'bg-white'} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className="w-full flex flex-row">

                <div className="w-full flex flex-row">

                    {/*Buttons Delete*/}
                    <div className="flex flex-col items-center mr-3">
                        <Button
                            className="rounded-full hover:bg-bgColor hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            // onClick={handleRemove}
                        >
                            <TrashIcon className="h-6 text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                        </Button>
                    </div>

                    {/*Sales Rep*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className="w-8 p-1 h-8 bg-bgColor rounded-full border hover:scale-105">
                            <div className="flex items-center justify-center h-full text-sm">{order.salesCode || "--"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm">{order.salesRep || "- - - - - - - - - - -"}</div>
                            <div className="text-xs">{order.salesEmail || "- - - - - - - - -"}</div>
                        </div>

                    </div>

                    {/*Customer*/}
                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className="w-fit p-1 px-4 h-8 bg-bgColor rounded-lg border hover:scale-105">
                            <div className="flex items-center justify-center h-full text-sm">{"- - - - -"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm">{"- - - - - - - - -"}</div>
                            <div className="text-xs">{"- - - - -"}</div>
                        </div>


                    </div>

                    {/*Status*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">



                        {order.status === "active" && (
                            <div>

                                <div className="w-fit p-1 h-8 bg-green-100 rounded-full border flex flex-row hover:scale-105">
                                    <CheckCircleIcon className="w-5 mr-3 hover:animate-pulse text-gray-400"/>
                                    <div className="flex items-center justify-center h-full text-xs mr-3 text-black">Active</div>
                                </div>

                            </div>
                        )}

                        {order.status === "pending" && (
                            <div>

                                <div className="w-fit p-1 h-8 bg-bgColor rounded-full border flex flex-row hover:scale-105">
                                    <ArrowPathIcon className="w-5 mr-3 hover:animate-spin"/>
                                    <div className="flex items-center justify-center h-full text-xs mr-3">Pending</div>
                                </div>

                            </div>
                        )}

                    </div>

                    {/*Industry*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                        <div className="w-1/2 p-1 h-8 bg-bgColor rounded-full border">
                            <div className="flex items-center justify-center h-full text-sm px-4">${numberWithCommas(order.grandTotal) || "- - - - -"}</div>
                        </div>

                    </div>



                    {/*Buttons DropDown*/}
                    <div className="flex flex-col items-center">
                        <Button
                            className="rounded-full hover:bg-bgColor mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            onClick={toggleVisibility}
                        >

                            {isVisible ? (
                                <ArrowUpCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:scale-110 m-auto"/>
                            ) : (
                                <ArrowDownCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:scale-110 m-auto"/>
                            )}
                        </Button>
                    </div>


                </div>

            </div>

            {/* Conditionally render content based on isVisible */}
            {isVisible && (
                <div>


                </div>
            )}

        </div>
    )
}

export default OrderCard;
