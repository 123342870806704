import React, {useEffect, useState} from 'react';
import SideBar from "./SideBar";
import {Button, Input, Select} from "@headlessui/react";
import {ArrowPathIcon, MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import ProductInput from "./OrderForm/ProductInput";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import productsData from "../products.json"

const NewOrder = () => {

    // eslint-disable-next-line
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');

    const { currentUser } = useAuth();
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [fullname, setFullname] = useState("");
    const [salesCode, setSalesCode] = useState("");
    const [salesEmail, setSalesEmail] = useState("");
    const [customerCode, setCustomerCode] = useState("Choose Customer...");

    const [industry, setIndustry] = useState("Select...");

    const [customers, setCustomers] = useState([]);

    const [priceType, setPriceType] = useState("AG");

    const [totals, setTotals] = useState([]);

    const [productInputs, setProductInputs] = useState([]);

    const [products] = useState(productsData.products);
    const [selectedProductIds, setSelectedProductIds] = useState([]);

    const [finalProducts, setFinalProducts] = useState([]);

    const [productToolTip, setProductToolTip] = useState(false);
    const [priceToolTip, setPriceToolTip] = useState(false);
    const [quantityToolTip, setQuantityToolTip] = useState(false);
    const [volumeToolTip, setVolumeToolTip] = useState(false);
    const [whToolTip, setWhToolTip] = useState(false);
    const [totalToolTip, setTotalToolTip] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [showProducts, setShowProducts] = useState(false); // To control product list visibility
    const [filteredProducts, setFilteredProducts] = useState(productsData.products);


    // Mapping of industry values to their display names
    const industryMapping = {
        BOTANICAL: "Botanical Garden",
        DISTRIB: "Product Distributor",
        EMPLOYEE: "PFC-EMPLOYEE",
        FARM: "Agriculture - Farm",
        GOLF: "Golf Course",
        GOVERNMENT: "Government Entity",
        INDUSTRIAL: "Industrial",
        LAWNTREE: "Lawn Care & Tree Care",
        MRSALE: "One Time Purchase Expected",
        MUNICIPAL: "Municipal/ School",
        "Non-Profit": "Non-Profit Account",
        OTHER: "Other",
        SPORTFLD: "Sportsfield"
    };

    // Mapping of industry values to their display names
    const pricingMapping = {
        AG: "Agriculture",
        DIST1: "Distributor NE/INTL",
        DIST2: "Distributor SE/CAN",
        NE: "NorthEast",
        SE: "SouthEast"
    };




    // Filter products by search term
    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        // Filter products by search term
        if (value) {
            setFilteredProducts(
                products.filter((product) =>
                    product.name.toLowerCase().includes(value.toLowerCase())
                )
            );
            setShowProducts(true); // Show the product list while typing
        } else {
            setShowProducts(false); // Hide the product list if the search bar is empty
        }
    };

    // Add a product to the productInputs list and reset the search bar
    const addProductInput = (product) => {
        // if (!selectedProductIds.includes(product.id)) {
        //     setProductInputs((prevInputs) => [...prevInputs, product]);
        //     setSelectedProductIds([...selectedProductIds, product.id]); // Add the product id to the selected list
        // } else {
        //     alert(`Product ${product.name} is already added! You may not add multiple of the same product`); // Optional: alert for user feedback
        // }

        setProductInputs((prevInputs) => [...prevInputs, product]);
        setSelectedProductIds([...selectedProductIds, product.id]); // Add the product id to the selected list
        setSearchTerm(''); // Clear the search bar
        setFilteredProducts(productsData.products);
        setShowProducts(false); // Hide the product list
    };

    // Callback to update the total for a specific product input
    const handleTotalChange = (index, newTotal) => {
        setTotals((prevTotals) => {
            const updatedTotals = [...prevTotals];
            updatedTotals[index] = parseFloat(newTotal); // Update total for that specific index
            return updatedTotals;
        });
    };

    const handleRemoveProduct = (index) => {
        // Get the product ID from the productInputs array
        const removedProductId = productInputs[index].id;

        // Update the totals and product inputs
        setTotals((prevTotals) => prevTotals.filter((_, i) => i !== index)); // Remove total based on index
        setProductInputs((prevInputs) => prevInputs.filter((_, indexInput) => indexInput !== index));

        // Update the selectedProductIds by removing the removed product's ID
        setSelectedProductIds((prevSelectedIds) =>
            prevSelectedIds.filter(id => id !== removedProductId)
        );

        console.log(products);
        console.log(totals);
    };

    // Calculate grand total
    const [grandTotal, setGrandTotal] = useState(0);
    useEffect(() =>{
        setGrandTotal(totals.reduce((acc, curr) => acc + (curr || 0), 0).toFixed(2));
    },[priceType, totals]);


    // fetch time and date
    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();

        // Update time every second
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setLname(userData.lname);
                        setSalesCode(userData.salesCode);
                        setFullname(userData.fname + " " + userData.lname);
                        setSalesEmail(userData.email);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setLname(userData.lname);
                            setSalesCode(userData.salesCode);
                            setSalesEmail(userData.email);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchCustomers = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeCustomers_${currentUser.uid}`);
                    if (storedActiveData) {
                        const customersData = JSON.parse(storedActiveData);
                        setCustomers(customersData);
                    } else {
                        const activeCustomers = db.collection("Customers").doc(currentUser.uid).collection("clients");
                        const snapshot = await activeCustomers.get();

                        if (!snapshot.empty) {
                            const activeData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setCustomers(activeData);
                            localStorage.setItem(`activeCustomers_${currentUser.uid}`, JSON.stringify(activeData));
                        } else {
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }


        fetchUserName();
        fetchCustomers();
    }, [currentUser]);

    const onUpdate = async () => {
        try {
            // Fetch user document from Firestore based on the current user's UID
            const userDoc = await db.collection('Users').doc(currentUser.uid).get();
            if (userDoc.exists) {
                // Get the user's name from the document data
                const userData = userDoc.data();
                setFname(userData.fname);
                setLname(userData.lname);
                setSalesCode(userData.salesCode);
                setSalesEmail(userData.email);

                setFullname(userData.fname + " " + userData.lname);

                // Save user data to localStorage
                localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
            } else {
                console.log('User document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }
    };

    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value);

        // Format date (e.g., "MM/DD/YYYY")
        const formattedDate = selectedDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        });

        setDeliveryDate(formattedDate); // Update the formatted date in state
    };

    // State to store selected customer data
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    // Handler for when the user selects a customer
    const handleSelectChange = (e) => {
        const index = e.target.value; // Get the index of the selected customer
        const customer = customers[index];

        if (customer) {
            setSelectedCustomer(customer);
            setIndustry(customer.industry);
            setPriceType(customer.priceType);
            setCustomerCode(customer.customerCode);
        } else {
            // Reset if no customer is selected
            setSelectedCustomer(null);
            setIndustry("");
            setPriceType("AG");
            setCustomerCode("Choose Customer...")
        }
    };

    // const handleProductAdd = (index, updatedProduct) => {
    //     const updatedProducts = [...productInputs];
    //     updatedProducts[index] = { ...updatedProducts[index], ...updatedProduct }; // Ensure we maintain other properties like id
    //     setFinalProducts(updatedProducts);
    // };


    // Function to handle adding new products (called from child component)
    const handleAddProduct = (index, productData) => {
        const updatedProducts = [...finalProducts];
        updatedProducts[index] = productData; // Update product data at the given index
        setFinalProducts(updatedProducts); // Update the state with the new product data
    };

    const onSubmitMain = async () => {
        // e.preventDefault();

        const collectionRef = db.collection("Orders").doc(currentUser.uid).collection("Pending");

        const parsedFormData = {

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            status: "pending",
            salesEmail: salesEmail,

            products: finalProducts,
            grandTotal: parseFloat(grandTotal),

        };

        try {
            await collectionRef.add(parsedFormData);
            alert(`Order Added to Pending`); // Optional: alert for user feedback

        } catch (error) {
            console.error('Error registering client:', error.message);
        }
    };


    return (
        <div className="bg-bgColor flex h-screen w-full mt-16">

            <SideBar/>

            <div className="flex flex-col w-full mt-3 mb-3 mr-3">

                {/*ORDER FORM HEADER [Sales Rep Info]*/}
                <div className="w-full flex mb-3">
                    <div className="text-2xl font- text-black w-1/4">New Order Form</div>
                    <div className="text-xl text-black bg-white w-3/4 flex rounded-md border drop-shadow shadow-gray-900">

                        {/*Buttons Delete*/}
                        <div className="flex flex-col items-center ml-1.5 mt-auto mb-auto">
                            <Button className="rounded-full hover:bg-green-200 hover:animate-spin mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"
                                    onClick={onUpdate}
                            >
                                <ArrowPathIcon className="h-6 text-borderColor hover:text-bgDarkGreen m-auto"/>
                            </Button>
                        </div>


                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="text-black font-semibold text-sm mr-3">Sales Rep:</div>
                            <div className="text-black text-sm rounded-md border px-2">{fullname}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="text-black font-semibold text-sm mr-3">Sales Rep Code:</div>
                            <div className="text-black text-sm rounded-md border px-2">{salesCode}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="text-black font-semibold text-sm mr-3">Date:</div>
                            <div className="text-black text-sm rounded-md border px-2">{date}</div>
                        </div>

                    </div>
                </div>

                {/*ORDER FORM BODY [Order Info]*/}
                <div className="w-full h-full rounded-md bg-white border drop-shadow shadow-gray-900 mb-3 p-3">

                    {/*CUSTOMER DETAILS*/}
                    <div className="w-full flex flex-row mb-1.5">

                        {/*CUSTOMER*/}
                        <div className="flex flex-col w-1/3 mr-3">
                            <div className="text-black text-sm mb-1">Customer</div>
                            <Select className="border drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-110"
                                    onChange={handleSelectChange}
                            >
                                <option value="">Select...</option>
                                {customers.map((customer, index) => (
                                    <option key={index} value={index}>
                                        {customer.name}
                                    </option>
                                ))}
                            </Select>

                        </div>

                        {/*INDUSTRY*/}
                        <div className="flex flex-col w-1/6 mr-3">
                            <div className="text-black text-sm mb-1">Industry</div>
                            <Select className="border drop-shadow shadow-gray-900 py-1 px-4 rounded-md"
                                    disabled={true}
                                    value={industry}
                            >
                                <option value={industry}>{industryMapping[industry] || "Select..."}</option>
                                {Object.entries(industryMapping).map(([value, displayName]) => (
                                    <option key={value} value={value}>
                                        {displayName}
                                    </option>
                                ))}
                            </Select>
                        </div>

                        {/*PRICING TYPE*/}
                        <div className="flex flex-col w-1/6 mr-3">
                            <div className="text-black text-sm mb-1">Pricing Type</div>
                            <Select className="border drop-shadow shadow-gray-900 py-1 px-4 rounded-md"
                                    value={priceType}
                                    disabled={true}
                                    // onChange={(event) => setPriceType(event.target.value)} // Update priceType on selection change
                            >
                                <option value={priceType}>{pricingMapping[priceType] || "Select..."}</option>
                                {Object.entries(pricingMapping).map(([value, displayName]) => (
                                    <option key={value} value={value}>
                                        {displayName}
                                    </option>
                                ))}

                                {/*<option value="AG">Agriculture</option>*/}
                                {/*<option value="DI1">Distributor NE/INTL</option>*/}
                                {/*<option value="DI2">Distributor SE/CAN</option>*/}
                                {/*<option value="NE">Northeast</option>*/}
                                {/*<option value="SE">Southeast</option>*/}
                            </Select>
                        </div>


                        {/*PRICING TYPE*/}
                        <div className="flex flex-col w-1/6">
                            <div className="text-black text-sm mb-1">Customer Code</div>

                            <div className="border flex flex-row w-full px-4 rounded-lg h-full items-center justify-center">
                                <div className="rounded-md w-full text-gray-600">{customerCode}</div>
                            </div>

                        </div>



                    </div>

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-full flex-col">

                        {/*PRODUCT SEARCH BAR*/}
                        <div className="w-full flex flex-row">

                            <div className="flex flex-row bg-bgColor drop-shadow border w-full p-1.5 rounded-md">
                                <div className="bg-white w-5/6 rounded-md border flex items-center justify-center hover:scale-y-110">
                                    <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>
                                    <Input
                                        className="rounded-md p-1.5 w-full"
                                        placeholder="Type to add product"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        onFocus={() => setShowProducts(true)}
                                        onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />
                                </div>
                                <Button className="bg-bgDarkGreen rounded-md ml-1.5 hover:scale-y-110 hover:bg-opacity-80 text-white w-1/6">Browse Customer Orders</Button>
                            </div>

                            {/* Search Results - Absolute positioning */}
                            {showProducts && filteredProducts.length > 0 && (
                                <ul className="absolute bg-white border rounded-md p-2 w-3/4 max-h-40 overflow-y-auto mt-12 z-10 shadow-lg">
                                    {filteredProducts.map((product) => (
                                        <li
                                            key={product.id}
                                            className="cursor-pointer p-2 hover:bg-gray-200"
                                            onMouseDown={() => addProductInput(product)} // Use onMouseDown to select item before blur
                                        >
                                            {product.name}
                                        </li>
                                    ))}
                                </ul>
                            )}



                        </div>

                        {/*PRODUCT LABELS*/}
                        <div className="w-full flex flex-row mb-1 mt-1 font-semibold">
                            <div className="w-full flex flex-row">

                                {/*PRODCUT NAME*/}
                                <div className="flex flex-row w-1/4">
                                    <div className="flex flex-col w-full">
                                        <div className="text-black text-sm"
                                             onMouseEnter={() => setProductToolTip(true)}
                                             onMouseLeave={() => setProductToolTip(false)}>Product{productInputs.length > 1 ? 's' : ''} [{productInputs.length}]</div>

                                        {productToolTip && (
                                            <div className="absolute bg-bgColor text-black text-xs rounded p-1 mt-1">
                                                Product Name and Code!
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/*PRODUCT PRICING/QUANT*/}
                                <div className="flex flex-row w-1/2">

                                    {/*PRICE*/}
                                    <div className="flex flex-row w-full px-4 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-black text-sm"
                                             onMouseEnter={() => setPriceToolTip(true)}
                                             onMouseLeave={() => setPriceToolTip(false)}>Price per U/M</div>

                                        {priceToolTip && (
                                            <div className="absolute bg-bgColor text-black text-xs rounded p-1 mt-1">
                                                Unit Price of Product!
                                            </div>
                                        )}
                                    </div>

                                    {/*QUANTITY*/}
                                    <div className="flex flex-row w-full px-4 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-black text-sm"
                                             onMouseEnter={() => setQuantityToolTip(true)}
                                             onMouseLeave={() => setQuantityToolTip(false)}>Quantity</div>

                                        {quantityToolTip && (
                                            <div className="absolute bg-bgColor text-black text-xs rounded p-1 mt-1">
                                                Quantity Ordered!
                                            </div>
                                        )}
                                    </div>

                                    {/*VOLUME*/}
                                    <div className="flex flex-row w-1/2 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-black text-sm"
                                             onMouseEnter={() => setVolumeToolTip(true)}
                                             onMouseLeave={() => setVolumeToolTip(false)}>U/M</div>

                                        {volumeToolTip && (
                                            <div className="absolute ml-28 bg-bgColor text-black text-xs rounded p-1 mt-1">
                                                Volume Unit Chosen!
                                            </div>
                                        )}
                                    </div>

                                </div>

                                {/*PRODUCT TOTAL*/}
                                <div className="flex flex-row w-1/4 mr-3 justify-end align-middle items-center">

                                    <div className="flex flex-row w-1/4 rounded-lg h-full items-center justify-center">
                                        <div className="px-1.5 w-full text-black text-sm"
                                             onMouseEnter={() => setWhToolTip(true)}
                                             onMouseLeave={() => setWhToolTip(false)}>WH</div>

                                        {whToolTip && (
                                            <div className="absolute ml-40 bg-bgColor text-black text-xs rounded p-1 mt-1">
                                                WareHouse Destination!
                                            </div>
                                        )}
                                    </div>

                                    <div className="ml-auto w-full flex flex-row justify-end">
                                        <div className="flex flex-row mt-auto mb-auto align-middle justify-end items-center">
                                            <div className="text-black text-sm mr-3"
                                                 onMouseEnter={() => setTotalToolTip(true)}
                                                 onMouseLeave={() => setTotalToolTip(false)}>Total</div>

                                            {totalToolTip && (
                                                <div className="absolute mr-16 bg-bgColor text-black text-xs rounded p-1 mt-1">
                                                    Total per Product!
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>

                        {/*PRODUCTINPUT MAP*/}
                        <div className="overflow-y-auto h-full max-h-[calc(42vh-2.85rem)] border-b-2 scroll">

                            {productInputs.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex w-full h-full justify-center items-center py-10">

                                    <div
                                        className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                        <div
                                            className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                            <p className="text-xl text-black">No Products in this Order</p>
                                            <p className="text-lg text-black mt-2">Add a Product</p>
                                            <p className="text-xs text-black">1] Search Product by name using the search
                                                bar above.</p>
                                            <p className="text-xs text-black">2] Browse the Digital Catalog by clicking
                                                on the button above.</p>
                                        </div>
                                    </div>

                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                productInputs.map((product, index) => (
                                    <ProductInput
                                        key={product.id}
                                        index={index}
                                        onRemove={handleRemoveProduct}
                                        onChange={handleTotalChange}
                                        onAdd={handleAddProduct}
                                        product={product}
                                        priceType={priceType}
                                    />
                                ))
                            )}
                        </div>

                        {/*PRODUCT TOTAL + NOTES + DELIVERY*/}
                        <div className="w-full h-1/4 mt-auto mb-16 flex">

                            <div className="w-1/2 h-full mr-auto rounded-lg flex flex-row p-1">

                            {/*NOTES SECTION*/}
                                <div className="w-1/2 h-full">
                                    <textarea
                                        id=""
                                        name=""
                                        type=""
                                        required
                                        autoComplete=""
                                        placeholder="Notes..."
                                        className="block w-full p-2 h-full rounded-md drop-shadow shadow-gray-900 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                {/*DELIVERY SECTION*/}
                                <div className="w-1/2 ml-5">
                                    <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
                                        Delivery Date
                                    </label>
                                    <input
                                        id="date-input"
                                        name="date"
                                        type="date"
                                        required
                                        autoComplete="off"
                                        placeholder="mm/dd/yyyy" // Keep the placeholder
                                        value={deliveryDate}
                                        onChange={handleDateChange}
                                        className="block w-full px-4 rounded-md drop-shadow shadow-gray-900 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />

                                    <div className="flex items-center mt-1.5">
                                        <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 rounded-full"/>
                                        <label htmlFor="default-checkbox" className="ms-2 text-sm text-black">Call Before Delivery</label>
                                    </div>

                                </div>

                            </div>

                            {/*TOTAL SECTION*/}
                            <div className="bg-bgColor drop-shadow border w-1/3 h-full ml-auto rounded-lg flex flex-row p-1.5">
                                <div className="w-1/2">
                                    <div className="h-1/3 flex items-center text-xs">SubTotal:</div>
                                    <div className="h-1/3 flex items-center text-bgLightGreen text-xs">Discount (+/-):
                                    </div>
                                    <div className="h-1/3 flex items-center text-lg">Total:</div>
                                </div>
                                <div className="w-2/3">
                                    <div className="h-1/3 flex items-center justify-end text-xs">
                                        <div className="text-borderColor mr-3">$</div>
                                        <div className="text-borderColor mr-3">{numberWithCommas(grandTotal)}</div>
                                        <div className="text-borderColor">USD</div>
                                    </div>
                                    <div className="h-1/3 flex items-center justify-end text-bgLightGreen text-xs">
                                        <div className="mr-3">$</div>
                                        <div className="mr-3">0</div>
                                        <div className="">USD</div>
                                    </div>
                                    <div className="h-1/3 flex items-center justify-end stext-lg">
                                        <div className="mr-3">$</div>
                                        <div className="mr-3">{numberWithCommas(grandTotal)}</div>
                                        <div className="">USD</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                {/*ORDER FORM FOOTER [Buttons]*/}
                <div className="w-full flex mb-3 mt-3">
                    <div className="text-xl text-black w-1/2">
                        <div className="flex w-full flex-row items-start align-middle justify-start h-full">
                            <Button className="bg-bgDarkGreen hover:scale-110 hover:bg-opacity-80 rounded-md py-1.5 px-6 text-white text-sm mr-3">Submit New Order</Button>
                            <Button className="bg-white hover:scale-110 hover:bg-opacity-50 rounded-md py-1.5 px-6 text-black text-sm border drop-shadow shadow-gray-900">Cancel</Button>
                        </div>
                    </div>
                    <div className="text-xl text-black w-1/2">
                        <div className="flex w-full flex-row items-end align-middle justify-end h-full">
                            <Button className="bg-bgLightGreen hover:scale-110 hover:bg-opacity-50 rounded-md py-1.5 px-6 text-white text-sm"
                                onClick={onSubmitMain}
                            >Save As Pending</Button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
};


export default NewOrder;
