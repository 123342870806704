import JugSVG from "../../PFC_Jug.svg"
import DrumSVG from "../../PFC_Drum.svg"
import ToteSVG from "../../PFC_Tote.svg"
import Drum55SVG from "../../PFC_Drum_55.svg"
import {Button, Input, Select} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {XMarkIcon} from "@heroicons/react/16/solid";

const ProductInput = ({ index, onRemove, onAdd, onChange, product, priceType }) => {

    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState("");
    const [productCode, setProductCode] = useState("");
    const [total, setTotal] = useState((price * quantity).toFixed(2));
    const [selectedVolume, setSelectedVolume] = useState("2.5");
    const [name, setName] = useState("");

    const [warehouse, setWarehouse] = useState("MAIN");
    const [disabled, setDisabled] = useState(false);



    const [selectedImage, setSelectedImage] = useState(JugSVG); // Initial image state

    // Sync price with product.price whenever product.price changes
    useEffect(() => {
        const calculatedTotal = (price * quantity).toFixed(2);
        setTotal(calculatedTotal);
        onChange(index, calculatedTotal); // Pass the total back to the parent component
        // eslint-disable-next-line
    }, [price, product, quantity, priceType, selectedVolume]);


    const handleQuantityChange = (e) => {
        const value = parseFloat(e.target.value) || 0; // Default to 0 if NaN
        setQuantity(value);
    };

    const getPriceBasedOnSelection = (product, priceType, quantity) => {
        // Create a price key based on the selected price type
        const priceKey = `${priceType}_Price`;

        // Check if the product has the priceKey and the specified quantity
        if (product[priceKey] && product[priceKey][quantity]) {
            return product[priceKey][quantity];
        }

        // Return default price if no specific price is found
        return product.price; // or any default behavior you want
    };

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    // Handle the remove action with animation
    const handleRemove = () => {
        setIsRemoving(true); // Trigger the animation
        setTimeout(() => onRemove(index), 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    };


    useEffect(() => {
        setName(product.name);
        if (product && priceType && selectedVolume) {
            const newPrice = getPriceBasedOnSelection(product, priceType, selectedVolume);
            setPrice(newPrice);

            if (newPrice === 1){
                setDisabled(true);
            } else {
                setDisabled(false);
            }

            switch (selectedVolume) {
                case "2.5":
                    setSelectedImage(JugSVG);
                    setProductCode(product.code.C);
                    break;
                case "30":
                    setSelectedImage(DrumSVG);
                    setProductCode(product.code.D30);
                    break;
                case "55":
                    setSelectedImage(Drum55SVG);
                    setProductCode(product.code.D55);
                    break;
                case "275":
                    setSelectedImage(ToteSVG);
                    setProductCode(product.code.T);
                    break;
                default:
                    setSelectedImage(JugSVG);
                    setProductCode(product.code.C);
            }
        }
    }, [price, product, quantity, priceType, selectedVolume]);

    const handleVolumeChange = (e) => {
        setSelectedVolume(e.target.value);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Automatically pass data back to the parent whenever any key state changes
    useEffect(() => {
        const productData = {
            price,
            name,
            quantity,
            productCode,
            total,
            selectedVolume,
            warehouse,
        };
        onAdd(index, productData); // Automatically pass the product data to the parent
    }, [price, name, quantity, productCode, total, selectedVolume, warehouse, index, onAdd]);

    return (
        <div className={`w-full flex flex-row border-b-2 mb-1.5 p-1 transition-transform duration-500 ease-in-out ${disabled ? 'bg-red-200 rounded-lg' : ''} ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*PRODCUT NAME*/}
            <div className="flex flex-row w-1/4 mr-3">
                <div className="flex flex-col mt-auto mb-auto w-1/6">
                    <img src={selectedImage} className="h-8 hover:scale-105" alt="Jug"/>
                </div>

                <div className="flex flex-col mt-auto mb-auto w-5/6">
                    <div className="text-black text-lg font-medium">{name}</div>
                    <div className="ml-3 text-xs text-borderColor">{productCode}</div>
                </div>
            </div>

            {/*PRODUCT PRICING/QUANT*/}
            <div className="flex flex-row w-1/2 mr-3">

                {/*PRICE*/}
                <div className="border-2 flex flex-row w-full px-4 rounded-lg h-full items-center justify-center">
                    <div className="text-borderColor text-lg mr-3">$</div>
                    <div className="rounded-md p-1.5 w-full">{numberWithCommas(price.toFixed(2)) || "No Price Found"}</div>
                    <div className="text-borderColor text-lg ml-3">USD</div>
                </div>

                <div className="flex flex-row px-4 h-full items-center justify-center">
                    <div className="text-borderColor text-xs">X</div>
                </div>

                {/*QUANTITY*/}
                <div className="border-2 flex flex-row w-full px-4 rounded-lg h-full items-center justify-center mr-3 hover:scale-y-105">
                    {/*// When quantity changes, trigger the onChange for recalculation*/}
                    <Input
                        className="rounded-md bg-hidden p-1.5 w-full"
                        type="string"
                        placeholder="Enter Quantity"
                        value={quantity}
                        disabled={disabled}
                        onChange={handleQuantityChange}
                    />

                    {/*// When price changes (in your select or other logic), ensure price updates are sent to parent.*/}
                </div>

                {/*VOLUME*/}
                <div className="border-2 flex flex-row w-1/2 rounded-lg h-full items-center justify-center  hover:scale-y-105">
                    <Select className="p-1.5 rounded-md w-full"
                            onChange={handleVolumeChange}
                            value={selectedVolume}
                            disabled={disabled}
                    >
                        <option value="2.5">2 x 2.5 gal</option>
                        <option value="30">30 gal</option>
                        <option value="55">55 gal</option>
                        <option value="275">275 gal</option>
                    </Select>
                </div>

            </div>

            {/*PRODUCT TOTAL*/}
            <div className="flex flex-row w-1/4 mr-3 justify-end align-middle items-center">

                <div className="border-2 flex flex-row w-1/4 rounded-lg h-full items-center justify-center hover:scale-y-105">
                    <Select
                        value={warehouse} // Will display the code (e.g., JCNJ) after selection
                        onChange={(e) => setWarehouse(e.target.value)} // Store the selected code in state
                        className="p-1.5 rounded-md w-full text-sm"
                        disabled={disabled}
                    >
                        <option value="MAIN">MAIN</option>
                        <option value="ADL">ADL</option>
                        <option value="CFNJ">CFNJ</option>
                        <option value="CNCT">CNCT</option>
                        <option value="FLFL">FLFL</option>
                        <option value="FNFL">FNFL</option>
                        <option value="FSFL">FSFL</option>
                        <option value="FWFL">FWFL</option>
                        <option value="GCPA">GCPA</option>
                        <option value="JCNJ">JCNJ</option>
                        <option value="MFNJ">MFNJ</option>
                        <option value="NJNJ">NJNJ</option>
                        <option value="PFSY">PFSY</option>
                        <option value="PGPA">PGPA</option>
                        <option value="RSNJ">RSNJ</option>
                        <option value="SJNJ">SJNJ</option>
                        <option value="WINY">WINY</option>
                    </Select>
                </div>

                <div className="ml-auto w-full flex flex-row justify-end">
                    <div className="flex flex-row mt-auto mb-auto align-middle justify-end items-center">
                        <div className="text-black text-lg mr-3 font-light">$</div>
                        <div className="text-black text-lg mr-3 font-light">{numberWithCommas(total)}</div>
                        <div className="text-borderColor text-sm">USD</div>
                    </div>

                    <Button
                        className="rounded-full hover:bg-bgColor ml-3 h-8 w-8 items-center justify-center align-middle"
                        onClick={handleRemove}>
                        <XMarkIcon className="h-6 text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                    </Button>
                </div>


            </div>

        </div>
    )
}

export default ProductInput;
