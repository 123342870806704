import React, {useState} from 'react';
import {Bars3Icon, XMarkIcon} from "@heroicons/react/16/solid";
import {Dialog, DialogPanel} from "@headlessui/react";
import logo from "../main-logo.png";
import {Link} from "react-router-dom";

const navigation = [
    { name: 'About', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Help Guide', href: '#' },
    { name: 'Reference Material', href: '#' },
]

const LandingPage = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className="bg-bgColor h-screen">

            <header className="absolute inset-x-0 top-0 z-50">
                <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">

                    <div className="flex lg:flex-1">

                        <a href="#" className="-m-1.5 p-1.5">
                            <img
                                alt=""
                                src={logo}
                                className="h-16 w-auto"
                            />
                        </a>
                    </div>


                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <Link className="text-sm font-semibold leading-6 text-gray-900"
                            to={'/Login'}>
                            Log in <span aria-hidden="true">&rarr;</span>
                        </Link>
                    </div>
                </nav>



                <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                    <div className="fixed inset-0 z-50" />
                    <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">Plant Food Company Inc.</span>
                                <img
                                    alt=""
                                    src={logo}
                                    className="h-8 w-auto"
                                />
                            </a>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {/*{navigation.map((item) => (*/}
                                    {/*    <a*/}
                                    {/*        key={item.name}*/}
                                    {/*        href={item.href}*/}
                                    {/*        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"*/}
                                    {/*    >*/}
                                    {/*        {item.name}*/}
                                    {/*    </a>*/}
                                    {/*))}*/}
                                </div>
                                <div className="py-6">
                                    {/*<a*/}
                                    {/*    href="#"*/}
                                    {/*    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"*/}
                                    {/*>*/}
                                    {/*    Log in*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </header>


            <div className="relative isolate px-6 pt-14 lg:px-8">

                <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                    <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                        {/*<div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">*/}
                        {/*    Announcing our next round of funding.{' '}*/}
                        {/*    <a href="#" className="font-semibold text-indigo-600">*/}
                        {/*        <span aria-hidden="true" className="absolute inset-0" />*/}
                        {/*        Read more <span aria-hidden="true">&rarr;</span>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                    <div className="text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            Effortless ordering to enhance our operations
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Streamline our ordering process with intuitive, user-friendly tools designed for speed and accuracy. Our app ensures orders are quickly submitted and efficiently tracked, allowing for seamless coordination and improved workflow across the team.
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="#"
                                className="rounded-md bg-bgDarkGreen px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Get started
                            </a>
                            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                                Learn more <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                >
                </div>
            </div>
        </div>
    )
};


export default LandingPage;
