import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useAuth} from "../contexts/authContext";
import {UserCircleIcon} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {Button, Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/16/solid";
import {MoonIcon, SunIcon} from "@heroicons/react/24/outline";
import {doSignOut} from "../firebase/auth";
import {useDarkMode} from "../contexts/darkModeContext";

const Header = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [fname, setFname] = useState("");
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');

    const [profileImage, setProfileImage] = useState('');


    const {darkMode, toggleDarkMode} = useDarkMode();


    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setProfileImage(userData.profileImageUrl);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setProfileImage(userData.profileImageUrl);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);


    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();

        // Update time every second
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString();
            setTime(currentTime);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="w-full bg-bgColor z-20 h-16 pr-3 pt-3 pl-3 fixed top-0 left-0">

            <div className="flex flex-row justify-between items-center w-full h-full rounded-md bg-white drop-shadow shadow-gray-900">

                <div className="w-1/3 flex flex-row ml-4">

                    <div className="mr-4 bg-bgColor p-1 border rounded-lg drop-shadow">{date}</div>
                    <div className="bg-bgColor p-1 border rounded-lg drop-shadow">{time}</div>

                    {/*<div className="ml-2">*/}
                    {/*    <input*/}
                    {/*        id=""*/}
                    {/*        name=""*/}
                    {/*        required*/}
                    {/*        autoComplete=""*/}
                    {/*        placeholder="Type to Search"*/}
                    {/*        className="block w-full p-2 rounded-md bg-bgLightGreen bg-opacity-40 border py-1.5 text-gray-900 shadow-sm placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>

                <div className="w-1/3">
                    <div className="w-full">
                        <input
                            type="checkbox"
                            id="spray"
                            name="spray"
                            checked={darkMode}
                            onChange={toggleDarkMode} // Call the toggle function from context
                            className="sr-only w-fit"
                        />
                        <label htmlFor="spray" className="cursor-pointer">
                            <div
                                className={`mr-auto ml-auto w-14 h-8 rounded-full relative ${darkMode ? 'bg-bgLightGreen' : 'bg-bgColor drop-shadow shadow-gray-900'}`}>
                                <div
                                    className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${darkMode ? 'bg-white translate-x-full' : 'bg-white'}`}>
                                    {darkMode ? (
                                        <MoonIcon
                                            className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <SunIcon
                                            className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                            aria-hidden="true"
                                        />
                                    )}
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                <div className="w-1/3 flex flex-row align-middle items-center">



                    <div className="rounded-full bg-bgColor justify-center items-center text-center w-10 h-10 ml-auto mr-2.5 drop-shadow border-2 border-bgDarkGreen">

                        {/* Display profile image if available, otherwise show placeholder */}
                        {profileImage ? (
                            <img
                                src={profileImage}
                                alt="Profile"
                                className="w-full h-full object-cover rounded-full"
                            />
                        ) : (
                            <UserCircleIcon className="text-gray-400" />
                        )}

                    </div>



                    <div className="text-xs mr-2.5">Welcome {fname}!</div>
                    <Menu as="div" className="relative mr-5 inline-block text-left">
                        <div>
                            <MenuButton
                                className="inline-flex hover:scale-105 w-full justify-center gap-x-1.5 rounded-md bg-white align-middle items-center p-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                Options
                                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
                            </MenuButton>
                        </div>

                        <MenuItems
                            transition
                            className="absolute right-0 z-10 pr-1 pl-1 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="py-1">


                                <MenuItem as="div">
                                    <Link to="/Profile" className="px-4 py-2 hover:bg-bgColor rounded-lg flex flex-row items-center text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                        <UserCircleIcon className="w-6 mr-5"/>
                                        Profile
                                    </Link>
                                </MenuItem>


                            </div>
                            <div className="py-1">
                                <MenuItem>
                                    <Button
                                        className="block w-full mr-auto ml-auto px-4 py-1 text-sm hover:bg-red-400 rounded-md bg-red-500 text-white"
                                        onClick={() => {
                                            doSignOut().then(() => {
                                                navigate('/Login');
                                            });
                                        }}
                                    >
                                        LogOut
                                    </Button>
                                </MenuItem>
                            </div>
                        </MenuItems>
                    </Menu>
                </div>

            </div>

        </div>
    );
};

export default Header;
