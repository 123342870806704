import React, {useEffect, useState} from 'react';
import SideBar from "./SideBar";
import {Button, Input, Select} from "@headlessui/react";
import {
    ArrowPathIcon,
    MagnifyingGlassIcon,
    PlusCircleIcon,
    TrashIcon, XCircleIcon
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import CustomerCard from "./Customers/CustomerCard";
import {Link} from "react-router-dom";

const Customers = () => {
    const { currentUser } = useAuth();

    const [status, setStatus] = useState("A");
    const [existing, setExisting] = useState(false);

    const [selectedIndustry, setSelectedIndustry] = useState("");

    const [customers, setCustomers] = useState([]);
    const [customersPending, setCustomersPending] = useState([]);

    const [filteredPendingCustomers, setFilteredPendingCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    // Handle dropdown change
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);

                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };


        const fetchCustomersPending = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`pendingCustomers_${currentUser.uid}`);
                    if (storedPendingData) {
                        const pendingData = JSON.parse(storedPendingData);
                        setCustomersPending(pendingData);
                        setFilteredPendingCustomers(pendingData);
                    } else {
                        const pendingCustomers = db.collection("Customers").doc(currentUser.uid).collection("pending");
                        const snapshot = await pendingCustomers.get();

                        if (!snapshot.empty) {
                            const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setCustomersPending(pendingData);
                            setFilteredPendingCustomers(pendingData);
                            localStorage.setItem(`pendingCustomers_${currentUser.uid}`, JSON.stringify(pendingData));
                        } else {
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }

        const fetchCustomersActive = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeCustomers_${currentUser.uid}`);
                    if (storedActiveData) {
                        const customersData = JSON.parse(storedActiveData);
                        setCustomers(customersData);
                        setFilteredCustomers(customersData);
                    } else {
                        const activeCustomers = db.collection("Customers").doc(currentUser.uid).collection("clients");
                        const snapshot = await activeCustomers.get();

                        if (!snapshot.empty) {
                            const activeData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setCustomers(activeData);
                            setFilteredCustomers(activeCustomers);
                            localStorage.setItem(`activeCustomers_${currentUser.uid}`, JSON.stringify(activeData));
                        } else {
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }



        fetchCustomersPending();
        fetchCustomersActive();
        fetchUserName();
    }, [currentUser]);



    const onUpdate = async () => {
        try {
            const pendingCustomers = db.collection("Customers").doc(currentUser.uid).collection("pending");
            const snapshot = await pendingCustomers.get();

            if (!snapshot.empty) {
                const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCustomersPending(pendingData);
                setFilteredPendingCustomers(pendingData);
                localStorage.setItem(`pendingCustomers_${currentUser.uid}`, JSON.stringify(pendingData));
            } else {
                console.log('Customer document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }

        try {
            const customers = db.collection("Customers").doc(currentUser.uid).collection("clients");
            const snapshot = await customers.get();

            if (!snapshot.empty) {
                const activeData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCustomers(activeData);
                setFilteredCustomers(activeData);
                localStorage.setItem(`activeCustomers_${currentUser.uid}`, JSON.stringify(activeData));
            } else {
                console.log('Customer document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }
    };


    const handlePendingSearch = (query) => {
        setSearchQuery(query);
        const filtered = customersPending.filter((customer) =>
            customer.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredPendingCustomers(filtered);
    }

    const handleActiveSearch = (query) => {
        setSearchQuery(query);
        const filtered = customers.filter((customer) =>
            customer.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCustomers(filtered);
    }


    // Function to toggle visibility
    const toggleVisibility = () => {
        setExisting(!existing);
    };


    const filterCustomersByIndustry = (industry) => {
        setSelectedIndustry(industry);
        const filtered = customers.filter(customer => industry === '' || customer.industry === industry);
        setFilteredCustomers(filtered);
    };

    const filterPendingCustomersByIndustry = (industry) => {
        setSelectedIndustry(industry);
        const filtered = customersPending.filter(customer => industry === '' || customer.industry === industry);
        setFilteredPendingCustomers(filtered);
    };

    const filtering = (e) => {
        if(status === "A"){
            filterCustomersByIndustry(e.target.value);
        }
        if (status === "P"){
            filterPendingCustomersByIndustry(e.target.value);
        }
    }

    const [locations, setLocations] = useState([]);

    const handleAddLocation = () => {
        setLocations([...locations, { nickName: '', contactName: '', contactPhone: '' }]);
    };

    const handleRemoveLocation = (index) => {
        const newLocations = locations.filter((_, i) => i !== index);
        setLocations(newLocations);
    };

    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        const newLocations = [...locations];
        newLocations[index][name] = value;
        setLocations(newLocations);
    };


    const [customerCode, setCustomerCode] = useState("");
    const [name, setName] = useState("");
    const [priceType, setPriceType] = useState("");
    const [industry, setIndustry] = useState("");

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [fullname, setFullname] = useState("");
    const [salesCode, setSalesCode] = useState("");
    const [salesEmail, setSalesEmail] = useState("");


    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setLname(userData.lname);
                        setSalesCode(userData.salesCode);
                        setSalesEmail(userData.email);

                        setFullname(userData.fname + " " + userData.lname);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setLname(userData.lname);
                            setSalesCode(userData.salesCode);
                            setSalesEmail(userData.email);

                            setFullname(userData.fname + " " + userData.lname);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);


    const onSubmitMain = async (e) => {
        e.preventDefault();

        const collectionRef = db.collection("Customers").doc(currentUser.uid).collection("clients");

        const parsedFormData = {

            // Sales Info
            customerCode: customerCode,
            name: name,
            priceType: priceType,
            industry: industry,

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            salesEmail: salesEmail,

            status: "active",

            existing: true,

            ShippingAddress: locations,
        };

        try {
            await collectionRef.add(parsedFormData);
            // handleNextTab();
            alert(`Customer Added to the Cloud`); // Optional: alert for user feedback

            setCustomerCode("");
            setName("");
            setPriceType("");
            setIndustry("");

            setLocations([]);

            onUpdate();

        } catch (error) {
            console.error('Error registering client:', error.message);
            // setIsRegistered(false);
        }
    };


    return (
        <div className="bg-bgColor flex h-screen w-full mt-16 overflow-y-auto">

            <SideBar/>

            <div className="flex flex-col w-full mt-3 mb-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-1">
                    <div className="text-2xl w-1/6 text-black mr-5 flex flex-row">Customers
                        {/*Buttons Delete*/}
                        <div className="flex flex-col items-center ml-3 mt-auto mb-auto">
                            <Button className="rounded-full hover:bg-green-200 hover:animate-spin mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"
                                onClick={onUpdate}
                            >
                                <ArrowPathIcon className="h-6 text-borderColor hover:text-bgDarkGreen m-auto"/>
                            </Button>
                        </div>
                    </div>

                    <div className="text-xl mr-1.5 text-black bg-white w-4/6 flex rounded-md border drop-shadow shadow-gray-900">

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="text-black font-semibold text-sm mr-3">Active Customers:</div>
                            <div className="text-black text-sm rounded-md border px-2">{customers.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="text-black font-semibold text-sm mr-3">Pending Customers:</div>
                            <div className="text-black text-sm rounded-md border px-2">{customersPending.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="text-black font-semibold text-sm mr-3">Filtered Customers:</div>
                            {status === "A" && (
                                <div>

                                    <div className="text-black text-sm rounded-md border px-2">{filteredCustomers.length}</div>

                                </div>
                            )}

                            {status === "P" && (
                                <div>

                                    <div className="text-black text-sm rounded-md border px-2">{filteredPendingCustomers.length}</div>

                                </div>
                            )}
                        </div>

                    </div>

                    <div className="w-1/6 text-black ml-1.5">
                        <Button className="bg-bgDarkGreen h-full w-full hover:scale-105 hover:bg-opacity-80 rounded-md py-1.5 px-6 text-white text-sm mr-3 flex flex-row align-middle justify-center items-center"
                            onClick={toggleVisibility}
                        >
                            {/*<ArrowDownTrayIcon className="w-6"/>*/}
                            {/*<div className="w-full">Download Excel</div>*/}

                            <PlusCircleIcon className="w-6"/>
                            <div className="w-full">Add Existing Customer</div>
                        </Button>
                    </div>


                </div>

                {/*Search bar row*/}
                <div className="w-full h-fit flex mb-3">

                    <div className="w-1/2 text-black mr-1.5 h-fit">
                        <div className="text-black text-sm mb-1">Customer</div>

                        <div className="bg-white w-full rounded-md border flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900">
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>


                            {status === "A" && (
                                <div className="w-full">

                                    <Input
                                        className="rounded-md p-1.5 w-full"
                                        placeholder="Search Active Customers by Name"
                                        value={searchQuery}
                                        onChange={(e) => handleActiveSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                            {status === "P" && (
                                <div className="w-full">

                                    <Input
                                        className="rounded-md p-1.5 w-full"
                                        placeholder="Search Pending Customers by Name"
                                        value={searchQuery}
                                        onChange={(e) => handlePendingSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                        </div>

                    </div>

                    <div className="w-1/6 text-black mr-1.5 ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            <div className="text-black text-sm mb-1">Status</div>
                            <Select className="border h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105"
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <option value="A">Active</option>
                                <option value="P">Pending</option>
                            </Select>
                        </div>

                    </div>

                    <div className="w-1/6 text-black mr-1.5 ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            <div className="text-black text-sm mb-1">Industry</div>

                            <Select className="border drop-shadow h-full shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105"
                                // onChange={(e) => filterCustomersByIndustry(e.target.value)}
                                    onChange={filtering}
                            >
                                <option value="">Select...</option>
                                <option value="BOTANICAL">Botanical Garden</option>
                                <option value="DISTRIB">Product Distributor</option>
                                <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                <option value="FARM">Agriculture - Farm</option>
                                <option value="GOLF">Golf Course</option>
                                <option value="GOVERNMENT">Government Entity</option>
                                <option value="INDUSTRIAL">Industrial</option>
                                <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                <option value="MRSALE">One Time Purchase Expected</option>
                                <option value="MUNICIPAL">Municipal/ School</option>
                                <option value="Non-Profit">Non-Profit Account</option>
                                <option value="OTHER">Other</option>
                                <option value="SPORTFLD">Sportsfield</option>
                            </Select>
                        </div>

                    </div>

                    <div className="w-1/6 text-black ml-1.5 h-full">

                        <div className="flex flex-col w-full mr-3 h-full">
                            <div className="text-black text-sm mb-1">Pricing Type</div>
                            <Select className="border h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105">
                                <option value="">Select...</option>
                                <option value="AG">Agriculture</option>
                                <option value="DIST1">Distributor NE/INTL</option>
                                <option value="DIST2">Distributor SE/CAN</option>
                                <option value="NE">Northeast</option>
                                <option value="SE">Southeast</option>
                            </Select>
                        </div>

                    </div>


                </div>

                {/*Customer Body*/}
                <div className="w-full h-full rounded-md mb-3">

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-full flex-col">


                        <div className="w-full h-full bg-white rounded-lg">

                            {/*HEADER*/}
                            <div className="w-full flex flex-row pl-3 pr-3">

                                <div className="w-full flex flex-row border-b-2">

                                    {/*Buttons Delete*/}
                                    <div className="flex flex-col items-center mr-3">
                                        <div className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-white m-auto"/>
                                        </div>
                                    </div>

                                    {/*Contact Name*/}
                                    <div className="w-1/3 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Sales Rep</div>
                                        </div>

                                    </div>

                                    {/*Contact Phone*/}
                                    <div className="w-1/3 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Customer</div>
                                        </div>


                                    </div>


                                    {/*Status*/}
                                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">

                                        <div className="w-fit p-1 h-8 rounded-full flex flex-row">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Status</div>
                                        </div>

                                    </div>


                                    {/**/}
                                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                                        <div className="w-fit p-1 h-8 rounded-full">
                                            <div className="flex items-center justify-center h-full text-sm font-semibold">Industry</div>
                                        </div>


                                    </div>



                                    {/*Buttons DropDown*/}
                                    <div className="flex flex-col items-center">
                                        <div className=" mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-white m-auto"/>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            {/*CUSTOMERS MAP*/}
                            <div className="overflow-y-auto h-full bg-white p-1 rounded-lg max-h-[calc(75vh-2.85rem)] scroll">


                                {status === "A" && (
                                    <div>

                                        {filteredCustomers.length === 0 ? (
                                            // Display custom dialog if sprays array is empty
                                            <div className="flex w-full h-full justify-center items-center py-10">

                                                <div
                                                    className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                                    <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                        <p className="text-xl text-black">No Products in this Order</p>
                                                        <p className="text-lg text-black mt-2">Add a Product</p>
                                                        <p className="text-xs text-black">1] Search Product by name using the search bar above.</p>
                                                        <p className="text-xs text-black">2] Browse the Digital Catalog by clicking on the button above.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            // Display SprayCard components if sprays array is not empty
                                            filteredCustomers.map((customer, index) => {
                                                return (
                                                    <CustomerCard
                                                        key={index}
                                                        index={index}
                                                        customer={customer}
                                                    />
                                                )
                                            })
                                        )}

                                    </div>
                                )}

                                {status === "P" && (
                                    <div>

                                        {filteredPendingCustomers.length === 0 ? (
                                            // Display custom dialog if sprays array is empty
                                            <div className="flex w-full h-full justify-center items-center py-10">

                                                <div
                                                    className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                                    <div
                                                        className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                        <p className="text-xl text-black">No Products in this Order</p>
                                                        <p className="text-lg text-black mt-2">Add a Product</p>
                                                        <p className="text-xs text-black">1] Search Product by name using
                                                            the search bar above.</p>
                                                        <p className="text-xs text-black">2] Browse the Digital Catalog by
                                                            clicking on the button above.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            // Display SprayCard components if sprays array is not empty
                                            filteredPendingCustomers.map((customer, index) => {
                                                return (
                                                    <CustomerCard
                                                        key={index}
                                                        index={index}
                                                        customer={customer}
                                                    />
                                                )
                                            })
                                        )}

                                    </div>
                                )}

                            </div>


                        </div>




                    </div>

                </div>

                {/*Customers Footer [Buttons]*/}
                <div className="w-full h-fit flex mb-3 mt-3">

                </div>

            </div>





            {existing && (
                <div className={`absolute top-0 left-0 w-full h-full flex justify-center items-center`}>
                    <div className="w-1/2 h-3/5 bg-white shadow-lg rounded-lg mt-auto mb-auto border-2 p-3 flex flex-col">

                        <Button className="absolute hover:bg-bgColor w-fit rounded-full p-1 hover:text-red-500 hover:scale-105" onClick={toggleVisibility}>
                            <XCircleIcon className="w-8"></XCircleIcon>
                        </Button>

                        {/*Header*/}
                        <div className="flex flex-col w-full mb-1.5 mt-3">
                            <div className="w-full text-center text-2xl text-bgDarkGreen font-semibold">Adding Existing Customer</div>
                            <div className="w-full text-center text-xs text-gray-400 mb-1">This is for adding in existing Customers that have already done Business with PlantFood Company and are logged in the ERP.</div>
                            <div className="w-full text-center text-xs text-gray-400">For New Customers Please See <Link to={"/NewCustomer"} className="bg-green-200 p-1 text-black rounded-full hover:bg-opacity-80">New Order Form</Link></div>
                        </div>


                        <form onSubmit={onSubmitMain} method="POST" className="w-full h-full mb-3 space-y-2">

                            {/*Form*/}
                            <div className="w-full flex h-full flex-col rounded-lg shadow p-3 border-2 overflow-y-auto max-h-[calc(46vh-2.85rem)] scroll">

                                <div className="text-black font-semibold text-lg">Name</div>

                                <div className="flex flex-row">

                                    {/*Customer Code*/}
                                    <div className="flex w-full flex-col mr-1.5">
                                        <label htmlFor="email" className="w-full text-sm leading-6 text-gray-900 mr-3">
                                            Customer Code
                                        </label>
                                        <div className="w-full">
                                            <input
                                                required
                                                placeholder="Customer Code"
                                                value={customerCode} onChange={(e) => setCustomerCode(e.target.value)}
                                                className="w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                    {/*Customer Name*/}
                                    <div className="flex w-full flex-col ml-1.5">
                                        <label htmlFor="email" className="w-full text-sm leading-6 text-gray-900 mr-3">
                                            Customer Name
                                        </label>
                                        <div className="w-full">
                                            <input
                                                required
                                                placeholder="Customer Name"
                                                value={name} onChange={(e) => setName(e.target.value)}
                                                className="w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="text-black font-semibold text-lg">Identifiers</div>

                                <div className="flex flex-row">

                                    {/*Customer Pricing Type*/}
                                    <div className="flex w-full flex-col mr-1.5">
                                        <label htmlFor="email" className="w-full text-sm leading-6 text-gray-900 mr-3">
                                            Pricing Type
                                        </label>
                                        <div className="w-full">
                                            <Select
                                                className="block h-full w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={priceType}
                                                onChange={(e) => setPriceType(e.target.value)}
                                            >
                                                <option value="">Select...</option>
                                                <option value="AG">Agriculture</option>
                                                <option value="DIST1">Distributor NE/INTL</option>
                                                <option value="DIST2">Distributor SE/CAN</option>
                                                <option value="NE">Northeast</option>
                                                <option value="SE">Southeast</option>

                                            </Select>
                                        </div>
                                    </div>
                                    {/*Industry*/}
                                    <div className="flex w-full flex-col ml-1.5">
                                        <label htmlFor="email"
                                               className="w-full text-sm leading-6 text-gray-900 mr-3">
                                            Industry
                                        </label>
                                        <div className="w-full">
                                            <Select
                                                className="block h-full w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={industry}
                                                onChange={(e) => setIndustry(e.target.value)}
                                            >
                                                <option value="">Select...</option>
                                                <option value="BOTANICAL">Botanical Garden</option>
                                                <option value="DISTRIB">Product Distributor</option>
                                                <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                                <option value="FARM">Agriculture - Farm</option>
                                                <option value="GOLF">Golf Course</option>
                                                <option value="GOVERNMENT">Government Entity</option>
                                                <option value="INDUSTRIAL">Industrial</option>
                                                <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                                <option value="MRSALE">One Time Purchase Expected</option>
                                                <option value="MUNICIPAL">Municipal/ School</option>
                                                <option value="Non-Profit">Non-Profit Account</option>
                                                <option value="OTHER">Other</option>
                                                <option value="SPORTFLD">Sportsfield</option>

                                            </Select>
                                        </div>
                                    </div>

                                </div>

                                <div className="text-black w-full font-semibold text-lg flex flex-row items-center">
                                    <div className="">Shipping Locations [{locations.length}]</div>
                                    <Button
                                        className="hover:bg-bgColor w-fit rounded-full p-1 ml-5 hover:text-green-400 hover:scale-105"
                                        onClick={handleAddLocation}>
                                        <PlusCircleIcon className="w-8"></PlusCircleIcon>
                                    </Button>
                                </div>

                                <div className="mt-2">

                                    {locations.map((location, index) => (

                                        <div key={index} className={`mb-2 p-1 border rounded-lg`}>
                                            <div className="flex flex-row w-full">
                                                <Button
                                                    className="hover:bg-bgColor text-red-500 w-fit h-fit mt-auto mb-auto rounded-full p-1 mr-1"
                                                    onClick={() => handleRemoveLocation(index)}
                                                >
                                                    <XCircleIcon className="w-6"/>
                                                </Button>

                                                <input
                                                    type="text"
                                                    name="nickName"
                                                    value={location.nickName}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    placeholder="NickName"
                                                    className="px-4 border rounded-lg w-full mr-1.5"
                                                />
                                                <input
                                                    type="text"
                                                    name="contactName"
                                                    value={location.contactName}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    placeholder="Ship to Contact"
                                                    className="px-4 border rounded-lg w-full ml-1.5 mr-1.5"
                                                />
                                                <input
                                                    type="text"
                                                    name="contactPhone"
                                                    value={location.contactphone}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    placeholder="Ship to Phone"
                                                    className="px-4 border rounded-lg w-full ml-1.5"
                                                />
                                            </div>
                                        </div>

                                    ))}

                                </div>


                            </div>

                            <Button className="bg-bgLightGreen w-2/3 mr-auto ml-auto rounded-lg p-1 hover:bg-opacity-80 flex flex-row items-center"
                                    type="submit">
                                <PlusCircleIcon className="w-8 text-white"></PlusCircleIcon>
                                <div className="w-full text-white font-semibold">Add Customer</div>
                            </Button>

                        </form>


                    </div>
                </div>
            )}


        </div>
    )
};


export default Customers;
