import React, { useState } from 'react';
import logo from "../main-logo.png";
import logoFull from "../PFC_Logo_Full.png"
import Header from "./Header";
import {
    BookOpenIcon,
    ChartBarSquareIcon,
    ClipboardDocumentListIcon,
    HomeIcon,
    UserGroupIcon,
    PlusCircleIcon,
    ArrowRightCircleIcon,
    ArrowLeftCircleIcon,
    UserPlusIcon, ArchiveBoxIcon, UsersIcon, WrenchScrewdriverIcon, ChatBubbleLeftEllipsisIcon
} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
const SideBar = () => {
    // Get the current pathname

    // eslint-disable-next-line
    const [currentPath, setCurrentPath] = useState(window.location.pathname); // Initialize currentPath

    const [collapse, setCollapse] = useState(() => {
        const savedState = localStorage.getItem('collapseState');
        return savedState === null ? false : JSON.parse(savedState);
    });

    const toggleCollapse = () => {
        setCollapse(prevCollapse => {
            const newCollapse = !prevCollapse;
            localStorage.setItem('collapseState', JSON.stringify(newCollapse));
            return newCollapse;
        });
    };

    return (

        <div className={`${collapse ? 'w-16' : 'w-80'} flex flex-col justify-between bg-bgDarkGreen m-3 rounded-md`}>

            <Header/>

            <div className="flex mr-auto ml-auto mt-2">
                <div className="flex flex-col mb-4 items-center justify-between">
                    <Link to="/">
                        {collapse ? <img
                                alt=""
                                src={logo}
                                className="h-14 hover:rotate-2 hover:scale-110 mt-9 w-auto"
                            />
                            :
                            <img
                                alt=""
                                src={logoFull}
                                className="h-14 hover:rotate-2 hover:scale-110 mt-9 w-auto bg-white rounded-lg p-0.5"
                            />}
                    </Link>
                    <div className="text-xxs text-center text-bgLightGreen ml-1.5 mr-1.5">Admin</div>

                </div>

                <div className="flex flex-row">
                    <button
                        className="justify-center items-end text-xl absolute left-1 text-white font-bold mt-auto mb-auto hover:scale-110 bg-bgLightGreen w-9 h-9 p-0.5 rounded-full"
                        onClick={toggleCollapse}>
                        {collapse ? <ArrowRightCircleIcon/> : <ArrowLeftCircleIcon/>}
                    </button>
                </div>
            </div>


            <div className="w-full h-full max-h-[calc(70vh-5.5rem)] scroll overflow-y-auto hover:transform flex flex-col mt-auto mb-auto">


                <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-1 mb-3">
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className={`bg-bgDarkGreen ${collapse ? 'px-0.5' : 'px-2'}`}>Main</div>
                    </div>

                    <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                </div>


                <Link to="/Home" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Home' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                    <div className={`w-5 h-5 rounded bg-bgLightGreen bg-opacity-50 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <HomeIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Home</div>
                </Link>

                <Link to="" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                    <div className={`w-5 h-5 rounded bg-bgLightGreen bg-opacity-50 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <ChartBarSquareIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Dashboard</div>
                </Link>


                <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-3 mb-3">
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className={`bg-bgDarkGreen ${collapse ? 'px-0.5' : 'px-2'}`}>Data</div>
                    </div>

                    <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                </div>


                <Link to="/Orders"
                      className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Orders' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                    <div
                        className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <ClipboardDocumentListIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Current Orders</div>
                </Link>


                <Link to=""
                      className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                    <div
                        className={`w-5 h-5 rounded bg-bgLightGreen bg-opacity-50 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <ArchiveBoxIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Archived Orders</div>
                </Link>


                <Link to="/Customers"
                      className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Customers' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                    <div
                        className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <UsersIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Customer Management</div>
                </Link>


                <Link to=""
                      className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                    <div
                        className={`w-5 h-5 rounded bg-bgLightGreen bg-opacity-50 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <UserGroupIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Sales Team</div>
                </Link>


                <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-3 mb-3">
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className={`bg-bgDarkGreen ${collapse ? 'px-0.5' : 'px-2'}`}>Tools</div>
                    </div>

                    <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                </div>


                <Link to=""
                      className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                    <div
                        className={`w-5 h-5 rounded bg-bgLightGreen bg-opacity-50 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <WrenchScrewdriverIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Program Builder</div>
                </Link>


                <Link to=""
                      className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                    <div
                        className={`w-5 h-5 rounded bg-bgLightGreen bg-opacity-50 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <ChatBubbleLeftEllipsisIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Messaging</div>
                </Link>

                <Link to=""
                      className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                    <div
                        className={`w-5 h-5 rounded bg-bgLightGreen bg-opacity-50 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <BookOpenIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-xs ${collapse ? 'hidden' : ''}`}>Catalog</div>
                </Link>



            </div>


            <div className="flex flex-col">
                <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-3 mb-3">
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className={`bg-bgDarkGreen ${collapse ? 'px-0.5' : 'px-2'}`}>Forms</div>
                    </div>

                    <div className="bg-white w-full rounded-full h-0.5"></div>
                </div>


                <Link to="/NewCustomer"
                      className={`flex items-center p-2 hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto mb-2 ${currentPath === '/NewCustomer' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900 rounded-full' : 'bg-transparent rounded-full'} ${collapse ? 'justify-center' : 'w-11/12 mb-1'}`}>
                    <div
                        className={`w-10 h-10 rounded-full bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <UserPlusIcon className={`text-white w-6 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-sm ${collapse ? 'hidden' : ''}`}>New Customer Form</div>
                </Link>

                <Link to="/NewOrder"
                      className={`flex items-center p-2 hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto mb-2 ${currentPath === '/NewOrder' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900 rounded-full' : 'bg-transparent rounded-full'} ${collapse ? 'justify-center' : 'w-11/12 mb-1'}`}>
                    <div
                        className={`w-10 h-10 rounded-full bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                        <PlusCircleIcon className={`text-white w-10 flex-none`} aria-hidden="true"/>
                    </div>
                    <div className={`text-white text-sm ${collapse ? 'hidden' : ''}`}>New Order Form</div>
                </Link>
            </div>


        </div>
    );
};

export default SideBar;

