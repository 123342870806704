import {Button, Select} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import { doc, setDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";
import {XMarkIcon} from "@heroicons/react/16/solid";
import fipsCode from "../../fipCodes.json";
import {
    ArrowDownCircleIcon,
    ArrowPathIcon,
    ArrowUpCircleIcon,
    ArrowUpTrayIcon, CheckCircleIcon, PlusCircleIcon,
    TrashIcon, XCircleIcon
} from "@heroicons/react/24/outline";
import {db} from "../../firebase/firebase";
import {useAuth} from "../../contexts/authContext";
import {Link} from "react-router-dom";

const AddressInput = ({ customer }) => {

    const [isVisible, setIsVisible] = useState(false); // State to track visibility
    const { currentUser } = useAuth();

    const [customerCode, setCustomerCode] = useState("");
    const [type, setType] = useState("");
    const [creditLimit, setCreditLimit] = useState("");
    const [priceCode, setPriceCode] = useState("");

    const [isAdding, setIsAdding] = useState(false);

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const toggleAdding = () => {
        setIsAdding(!isAdding);
    }

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    // // Handle the remove action with animation
    // const handleRemove = () => {
    //     setIsRemoving(true); // Trigger the animation
    //     // setTimeout(() => onRemove(id), 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    // };

    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };


    // Function to move document from "pending" to "active" collection and update status
    const moveToActive = async (customerId) => {
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);

            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the pending collection");
            }

            // Get the customer data and set status to 'active'
            const customerData = customerSnapshot.data();
            const updatedCustomerData = { ...customerData, status: "active" };

            // Reference to the destination document in the "clients" collection
            const activeDocRef = doc(db, `Customers/${currentUser.uid}/clients`, customerId);

            // Copy document to the "clients" collection with updated status
            await setDoc(activeDocRef, updatedCustomerData);

            // Delete document from the "pending" collection
            await deleteDoc(customerDocRef);

            console.log("Customer moved to clients collection and status updated to active.");
            alert("Customer moved to clients collection and status updated to active.");
        } catch (error) {
            console.error("Error moving customer to clients collection:", error);
            alert("Error moving customer to clients collection.");
        }
    };



    // const submitUpdate = async (customerId) => {
    //     try {
    //         // Reference to the customer document in the "pending" collection
    //         const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);
    //         const collectionRef = db.collection('Customers').doc(currentUser.uid).collection("pending");
    //
    //         // Get the customer document from the "pending" collection
    //         const customerSnapshot = await getDoc(customerDocRef);
    //         if (!customerSnapshot.exists()) {
    //             throw new Error("Customer not found in the pending collection");
    //         }
    //
    //         // Get the customer data and set status to 'active'
    //         const customerData = customerSnapshot.data();
    //         const updatedCustomerData = {
    //             ...customerData,
    //             customerCode: customerCode,
    //             type: type,
    //             creditLimit: creditLimit,
    //             priceType: priceCode,
    //         };
    //
    //         // Update the document directly with customerDocRef
    //         await collectionRef.doc(customerId).update(updatedCustomerData);
    //
    //         console.log("Customer successfully updated with internal setup.");
    //         alert("Customer successfully updated with internal setup.");
    //     } catch (error) {
    //         console.error("Customer update Failed.", error);
    //         alert("Customer update Failed.");
    //     }
    // };


    const submitUpdate = async (customerId) => {
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);

            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the pending collection");
            }

            // Get the customer data and set status to 'active'
            const customerData = customerSnapshot.data();
            const updatedCustomerData = {
                ...customerData,
                customerCode,
                type,
                creditLimit,
                priceType: priceCode,
            };

            // Update the document directly with customerDocRef
            await updateDoc(customerDocRef, updatedCustomerData);

            console.log("Customer successfully updated with internal setup.");
            alert("Customer successfully updated with internal setup.");
        } catch (error) {
            console.error("Customer update Failed.", error);
            alert("Customer update Failed.");
        }
    };



    return (
        <div className={`w-full flex flex-col border-b-2 p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : 'bg-white'} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className="w-full flex flex-row">

                <div className="w-full flex flex-row">

                    {/*Buttons Delete*/}
                    <div className="flex flex-col items-center mr-3">
                        <Button
                            className="rounded-full hover:bg-bgColor hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            // onClick={handleRemove}
                        >
                            <TrashIcon className="h-6 text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                        </Button>
                    </div>

                    {/*Sales Rep*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-8 p-1 h-8 bg-bgColor rounded-full border hover:scale-105 ${customer.existing ? 'bg-yellow-50' : 'bg-bgColor'}`}>
                            <div className="flex items-center justify-center h-full text-sm">{customer.salesCode || "--"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm">{customer.salesRep || "- - - - - - - - - - -"}</div>
                            <div className="text-xs">{customer.salesEmail || "- - - - - - - - -"}</div>
                        </div>

                    </div>

                    {/*Customer*/}
                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-fit p-1 px-4 h-8 rounded-lg border hover:scale-105 ${customer.existing ? 'bg-yellow-50' : 'bg-bgColor'}`}>
                            <div className="flex items-center justify-center h-full text-sm">{customer.customerCode || "- - - - -"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm">{customer.name || "- - - - - - - - -"}</div>
                            <div className={`text-xs ${customer.existing ? 'hidden' : ''}`}>{customer.contactPosition || "- - - - -"} | {formatPhoneNumber(customer.contactPhone) || "(---) --- - ----"}</div>
                        </div>


                    </div>

                    {/*Status*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">



                        {customer.status === "active" && (
                            <div>

                                <div className={`w-fit p-1 h-8 rounded-full border flex flex-row hover:scale-105 ${customer.existing ? 'bg-yellow-50' : 'bg-green-100'}`}>
                                    <CheckCircleIcon className="w-5 mr-3 hover:animate-pulse text-gray-400"/>
                                    <div className="flex items-center justify-center h-full text-xs mr-3 text-black">
                                        {customer.existing ? 'Existing' : 'Active'}
                                    </div>
                                </div>

                            </div>
                        )}

                        {customer.status === "pending" && (
                            <div>

                                <div className="w-fit p-1 h-8 bg-bgColor rounded-full border flex flex-row hover:scale-105">
                                    <ArrowPathIcon className="w-5 mr-3 hover:animate-spin"/>
                                    <div className="flex items-center justify-center h-full text-xs mr-3">Pending</div>
                                </div>

                            </div>
                        )}


                        {/*<div className="w-fit p-1 h-8 bg-bgColor rounded-full border flex flex-row hover:scale-105">*/}
                        {/*    <ArrowPathIcon className="w-5 mr-3 hover:animate-spin"/>*/}
                        {/*    <div className="flex items-center justify-center h-full text-xs mr-3">Pending</div>*/}
                        {/*</div>*/}

                    </div>

                    {/*Industry*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                        <div className="w-1/2 p-1 h-8 bg-bgColor rounded-full border">
                            <div className="flex items-center justify-center h-full text-sm px-4">{customer.industry || "- - - - -"}</div>
                        </div>

                        {/*<div className="justify-center flex flex-col h-full ml-2.5">*/}
                        {/*    <div className="text-sm">{customer.name}</div>*/}
                        {/*    <div className="text-xs">{customer.contactPosition} | {customer.contactPhone}</div>*/}
                        {/*</div>*/}

                        {/*<Button className="w-2/3 h-full bg-bgLightGreen rounded-lg text-white hover:bg-opacity-80">Add to ERP</Button>*/}


                    </div>



                    {/* Buttons DropDown */}
                    <div className={`flex flex-col items-center ${customer.existing ? 'mr-8' : ''}`}>
                        <Button
                            className={`rounded-full hover:bg-bgColor mt-auto mb-auto h-8 w-8 items-center justify-center align-middle 
                            ${customer.existing ? 'hidden' : ''}`}
                            onClick={toggleVisibility}
                        >
                            {isVisible ? (
                                <ArrowUpCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:scale-110 m-auto"
                                />
                            ) : (
                                <ArrowDownCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:scale-110 m-auto"
                                />
                            )}
                        </Button>
                    </div>

                </div>

            </div>

            {/* Conditionally render content based on isVisible */}
            {isVisible && (
                <div>

                    <div className="w-full h-96 rounded-lg mt-3 border pr-2 pl-2 pt-2 flex flex-row space-x-3">

                        {/*Main Done*/}
                        <div className="w-1/5 h-full">

                            <div className="w-full text-center text-black text-sm">Main</div>

                            <div className="w-full flex flex-col border rounded-lg p-1 space-y-2 overflow-y-auto h-full max-h-[calc(42vh-2.85rem)]">

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Customer Name</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.name || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Management Name</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.managementName || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Street Address [1]</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.address1 || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Street Address [2]</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.address2 || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">City</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.city || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">State</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.state || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Zip</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.zip || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">County</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.county || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Fips Code</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{formatFipsCode(customer.fips) || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Country</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.country || "- - - - -"}</div>
                                </div>

                            </div>

                        </div>

                        {/*Contacts Done*/}
                        <div className="w-1/5 h-full">

                            <div className="w-full text-center text-black text-sm">Contacts</div>

                            <div className="w-full flex flex-col border rounded-lg p-1 space-y-2 overflow-y-auto h-full max-h-[calc(42vh-2.85rem)]">

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Contact Name</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.contact || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Contact Position</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.contactPosition || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Contact Phone</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{formatPhoneNumber(customer.contactPhone) || "(---) --- - ----"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Contact Email</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.contactEmail || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Website</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.website || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Billing Contact</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.billingContact || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Billing Phone</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{formatPhoneNumber(customer.billingPhone) || "(---) --- - ----"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Invoice Email</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.invoiceEmails || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Statement Email</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.statementEmails || "- - - - -"}</div>
                                </div>

                            </div>

                        </div>

                        {/*Shipping*/}
                        <div className="w-1/5 h-full">

                            <div className="w-full text-center text-black text-sm">Shipping</div>

                            <div className="w-full flex flex-col border rounded-lg p-1 space-y-2 overflow-y-auto h-full max-h-[calc(42vh-2.85rem)]">

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Contact Name</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.name || "- - - - -"}</div>
                                </div>


                            </div>

                        </div>

                        {/*Finances Done*/}
                        <div className="w-1/5 h-full">

                            <div className="w-full text-center text-black text-sm">Finances</div>

                            <div className="w-full flex flex-col border rounded-lg p-1 space-y-2 overflow-y-auto h-full max-h-[calc(42vh-2.85rem)]">

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Tax ID</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.taxID || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Sales Tax Status</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.salesTaxStatus || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Tax Exempt Code</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.taxExempCode || "- - - - -"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Payment Type</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.paymentType || "- - - - -"}</div>
                                </div>

                            </div>

                        </div>

                        {/*Delivery Done*/}
                        <div className="w-1/5 h-full">

                            <div className="w-full text-center text-black text-sm">Delivery</div>

                            <div className="w-full flex flex-col border rounded-lg p-1 space-y-2 overflow-y-auto h-full max-h-[calc(42vh-2.85rem)]">

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Tractor Trailer Accessible?</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.TTA ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Limited Access Site?</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.LAS ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Loading Dock Available?</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.LDA ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Lift Gate & Pallet Jack Required?</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.PJR ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Delivery Appointment Required?</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.DAR ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Heated Storage Available</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.HSA ? "Yes" : "No"}</div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <div className="text-xs mb-0.5 font-semibold">Forklift Available w/ 3,000 lbs Capacity</div>
                                    <div className="text-xs p-0.5 border rounded-lg">{customer.FLC ? "Yes" : "No"}</div>
                                </div>

                            </div>

                        </div>


                    </div>



                    {customer.status === "pending" && (
                        <div>

                            {/*Buttons*/}
                            <div className="w-full mt-3 flex flex-row h-full">

                                <Button
                                    className="w-1/6 p-1 mr-auto bg-bgLightGreen rounded-lg text-white hover:bg-opacity-80 flex flex-row items-center justify-center"
                                    onClick={() => moveToActive(customer.id)}
                                >
                                    <ArrowPathIcon className="w-4 mt-auto mb-auto mr-2"/> Update Status</Button>

                                <Button
                                    className="w-1/6 p-1 bg-bgLightGreen rounded-lg text-white hover:bg-opacity-80 flex flex-row items-center justify-center"
                                    onClick={toggleAdding}
                                >
                                    <ArrowUpTrayIcon className="w-4 mt-auto mb-auto mr-2"/>Internal Setup</Button>

                            </div>


                        </div>
                    )}

                </div>
            )}


            {isAdding && (
                <div className="absolute top-0 left-0 h-screen w-full flex justify-center items-center">
                    <div className="w-1/3 h-1/2 bg-white drop-shadow shadow-2xl rounded-lg mt-auto mb-auto border-2 p-3 flex flex-col">

                        <Button className="hover:bg-bgColor w-fit rounded-full p-1 hover:text-red-500 hover:scale-105"
                                onClick={toggleAdding}>
                            <XCircleIcon className="w-8"></XCircleIcon>
                        </Button>


                        {/*Header*/}
                        <div className="flex flex-col w-full mb-1.5">
                            <div className="w-full text-center text-xl text-bgDarkGreen font-semibold">[{customer.name}]
                                Internal Setup
                            </div>
                            <div className="w-full text-center text-xs text-bgLightGreen mb-1">Please ensure all of the
                                fields are completed
                            </div>
                        </div>


                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                submitUpdate(customer.id); // Call submitUpdate with the correct customerId
                            }}
                            method="POST" className="h-full w-full flex flex-col justify-between"
                        >

                            {/*Form*/}
                            <div className="w-full h-full flex flex-col border-2 shadow rounded-lg p-3 mb-3 space-y-2 overflow-y-auto max-h-[calc(75vh-2.85rem)] scroll">

                                {/*Customer Code*/}
                                <div className="flex w-full flex-row items-center">
                                    <label htmlFor=""
                                           className="w-1/4 text-sm font-medium leading-6 text-gray-900 mr-3">
                                        Customer Code
                                    </label>
                                    <div className="mt-2 w-3/4">
                                        <input
                                            required
                                            value={customerCode} onChange={(e) => setCustomerCode(e.target.value)}
                                            className="w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                {/*Type*/}
                                <div className="flex w-full flex-row items-center">
                                    <label htmlFor="email"
                                           className="w-1/4 text-sm font-medium leading-6 text-gray-900 mr-3">
                                        Type
                                    </label>
                                    <div className="mt-2 w-3/4">
                                        <input
                                            required
                                            value={type} onChange={(e) => setType(e.target.value)}
                                            className="w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                {/*Credit Limit*/}
                                <div className="flex w-full flex-row items-center">
                                    <label htmlFor="email"
                                           className="w-1/4 text-sm font-medium leading-6 text-gray-900 mr-3">
                                        Credit Limit
                                    </label>
                                    <div className="mt-2 w-3/4">
                                        <input
                                            required
                                            value={creditLimit} onChange={(e) => setCreditLimit(e.target.value)}
                                            className="w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                {/*Price Code*/}
                                <div className="flex w-full flex-row items-center">
                                    <label htmlFor="email"
                                           className="w-1/4 text-sm font-medium leading-6 text-gray-900 mr-3">
                                        Price Code
                                    </label>
                                    <div className="mt-2 w-3/4">
                                        <Select
                                            className="border w-full drop-shadow shadow-gray-900 py-2 px-4 rounded-md"
                                            value={priceCode} // Bound to the priceCode state
                                            required
                                            onChange={(event) => {
                                                const selectedValue = event.target.value;
                                                setPriceCode(selectedValue); // Update priceCode state
                                                console.log("Selected Price Code:", selectedValue); // Debugging to verify if it's working
                                            }}
                                        >
                                            <option value="AG">Agriculture</option>
                                            <option value="DIST1">Distributor NE/INTL</option>
                                            <option value="DIST2">Distributor SE/CAN</option>
                                            <option value="NE">Northeast</option>
                                            <option value="SE">Southeast</option>
                                        </Select>
                                    </div>
                                </div>

                            </div>

                            <Button className="bg-bgLightGreen w-2/3 mr-auto ml-auto rounded-lg p-1 hover:bg-opacity-80 flex flex-row items-center" type="submit">

                                <PlusCircleIcon className="w-8 text-white"></PlusCircleIcon>
                                <div className="w-full text-white font-semibold">Add Internal Setup</div>
                            </Button>

                        </form>



                    </div>
                </div>
            )}


        </div>
    )
}

export default AddressInput;
