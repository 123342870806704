import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {
    ArrowDownCircleIcon, ArrowPathIcon,
    ArrowUpCircleIcon,
    EnvelopeIcon, GlobeAmericasIcon, IdentificationIcon, MapIcon,
    PhoneIcon, PrinterIcon,
    UserCircleIcon, UserIcon
} from "@heroicons/react/24/outline";
import {useAuth} from "../contexts/authContext";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { storage, db } from '../firebase/firebase';
import {Button} from "@headlessui/react"; // Adjust the import based on your structure

const Profile = () => {

    const { currentUser } = useAuth();


    const [image, setImage] = useState(null); // To store the selected image file
    const [uploading, setUploading] = useState(false); // Upload status
    const [imageURL, setImageURL] = useState(''); // To store the uploaded image URL


    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [fullname, setFullname] = useState("");
    const [salesCode, setSalesCode] = useState("");
    const [profileImage, setProfileImage] = useState('');
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [address, setAddress] = useState("");
    const [region, setRegion] = useState("");
    const [supervisor, setSupervisor] = useState("");
    const [accountLevel, setAccountLevel] = useState("");

    const [isVisible, setIsVisible] = useState(false); // State to track visibility

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    // Function to handle the image file input
    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    // Function to upload the image to Firebase Storage and save URL to Firestore
    const handleUpload = async () => {
        if (!image) return;

        setUploading(true);
        try {
            // Create a storage reference
            const storageRef = ref(storage, `profileImages/${currentUser.uid}/${image.name}`);

            // Upload the image file to Firebase Storage
            const snapshot = await uploadBytes(storageRef, image);

            // Get the download URL for the uploaded image
            const downloadURL = await getDownloadURL(snapshot.ref);

            // Save the download URL to Firestore
            await setDoc(doc(db, 'Users', currentUser.uid), {
                profileImageUrl: downloadURL,
            }, { merge: true }); // Merge to avoid overwriting existing data

            setImageURL(downloadURL); // Update the image URL state
            alert('Profile image uploaded successfully!');
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Error uploading image, please try again.');
        } finally {
            setUploading(false);
        }
    };

    const pricingMapping = {
        AG: "Agriculture",
        DIST1: "Distributor NE/INTL",
        DIST2: "Distributor SE/CAN",
        NE: "North East",
        SE: "South East"
    };

    const supervisorMapping = {
        GP: "Grant Platz",
    }

    const accountLevelMapping = {
        D: "Developer",
        A: "Admin",
        CSR: "CSR (Customer Service Rep)",
        PFC: "PFC Fulfillment",
        DIST: "Distributor",
        S: "Sales Rep"
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setLname(userData.lname);
                        setSalesCode(userData.salesCode);
                        setFullname(userData.fname + " " + userData.lname);
                        setProfileImage(userData.profileImageUrl);
                        setEmail(userData.email);
                        setPhone(userData.phone);
                        setFax(userData.fax);
                        setAddress(userData.address);
                        setRegion(userData.region);
                        setSupervisor(userData.supervisor);
                        setAccountLevel(userData.accountLevel);

                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setLname(userData.lname);
                            setSalesCode(userData.salesCode);
                            setProfileImage(userData.profileImageUrl);
                            setEmail(userData.email);
                            setPhone(userData.phone);
                            setFax(userData.fax);
                            setAddress(userData.address);
                            setRegion(userData.region);
                            setSupervisor(userData.supervisor);
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);


    const onUpdate = async () => {
        try {
            // Fetch user document from Firestore based on the current user's UID
            const userDoc = await db.collection('Users').doc(currentUser.uid).get();
            if (userDoc.exists) {
                // Get the user's name from the document data
                const userData = userDoc.data();
                setFname(userData.fname);
                setLname(userData.lname);
                setSalesCode(userData.salesCode);
                setProfileImage(userData.profileImageUrl);
                setEmail(userData.email);
                setPhone(userData.phone);
                setFax(userData.fax);
                setAddress(userData.address);
                setRegion(userData.region);
                setSupervisor(userData.supervisor);
                setAccountLevel(userData.accountLevel);

                setFullname(userData.fname + " " + userData.lname);

                // Save user data to localStorage
                localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
            } else {
                console.log('User document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }
    };



    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };



    return (
        <div className="bg-bgColor flex h-full w-full mt-16">

            <SideBar/>

            {/*Account Info Side*/}
            <div className="w-1/3 bg-white mt-3 mb-3 rounded-lg p-3">

                {/*Buttons Delete*/}
                <div className="absolute items-center">
                    <Button className="rounded-full hover:bg-green-200 hover:animate-spin mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"
                            onClick={onUpdate}
                    >
                        <ArrowPathIcon className="h-6 text-borderColor hover:text-bgDarkGreen m-auto"/>
                    </Button>
                </div>


                <div className="flex flex-col h-full">

                    {/*Profile Image / Selector*/}
                    <div className="flex flex-col h-1/3 mb-3 justify-center bg items-center">

                        <div className="w-60 h-60 bg-bgColor rounded-full items-center justify-center flex border-2 border-bgDarkGreen">

                            {profileImage ? (
                                <img
                                    src={profileImage}
                                    alt="Profile"
                                    className="w-full h-full object-cover rounded-full"
                                />
                            ) : (
                                <UserCircleIcon className="stroke-1 text-white" />
                            )}
                        </div>

                        <div className="flex w-full">


                            <div className="flex flex-col items-center mr-5">
                                <Button
                                    className="rounded-full hover:bg-bgColor mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={toggleVisibility}
                                >

                                    {isVisible ? (
                                        <ArrowUpCircleIcon
                                            className="h-6 text-borderColor hover:text-bgLightGreen hover:scale-110 m-auto"/>
                                    ) : (
                                        <ArrowDownCircleIcon
                                            className="h-6 text-borderColor hover:text-bgLightGreen hover:scale-110 m-auto"/>
                                    )}
                                </Button>
                            </div>


                            {isVisible && (
                                <div>
                                    <input type="file" onChange={handleImageChange} accept="image/*"/>
                                    <button onClick={handleUpload} disabled={!image || uploading}
                                            className="border px-4 rounded-lg hover:bg-bgColor">
                                        {uploading ? 'Uploading...' : 'Upload'}
                                    </button>
                                </div>
                            )}

                        </div>

                    </div>

                    {/*Account Info*/}
                    <div className="flex w-full flex-col h-full border rounded-lg p-3">

                        {/*Name*/}
                        <div className="text-black text-xl w-full flex flex-col items-center border-b mb-3">
                            <div className="font-semibold">{fullname || "- - - - -"}</div>
                            <div className="text-xxs">{accountLevelMapping[accountLevel] || "- - - - -"}</div>
                        </div>

                        {/*Email*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className="bg-bgColor w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle">
                                <EnvelopeIcon className="w-5"/>
                            </div>

                            <div className="">{email || "- - - - -"}</div>

                            <div className="ml-auto">Email</div>
                        </div>

                        {/*Phone*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className="bg-bgColor w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle">
                                <PhoneIcon className="w-5"/>
                            </div>

                            <div className="">{formatPhoneNumber(phone) || "- - - - -"}</div>

                            <div className="ml-auto">Phone</div>
                        </div>

                        {/*Fax*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className="bg-bgColor w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle">
                                <PrinterIcon className="w-5"/>
                            </div>

                            <div className="">{formatPhoneNumber(fax) || "- - - - -"}</div>

                            <div className="ml-auto">Fax</div>
                        </div>

                        {/*Address*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className="bg-bgColor w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle">
                                <MapIcon className="w-5"/>
                            </div>

                            <div className="">{address || "- - - - -"}</div>

                            <div className="ml-auto">Address</div>
                        </div>

                        {/*Region*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className="bg-bgColor w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle">
                                <GlobeAmericasIcon className="w-5"/>
                            </div>

                            <div className="">{pricingMapping[region] || "- - - - -"}</div>

                            <div className="ml-auto">Region</div>
                        </div>

                        {/*Supervisor*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className="bg-bgColor w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle">
                                <UserIcon className="w-5"/>
                            </div>

                            <div className="">{supervisorMapping[supervisor] || "- - - - -"}</div>

                            <div className="ml-auto">Supervisor</div>
                        </div>

                        {/*Sales Code*/}
                        <div className="w-full flex flex-row items-center">

                            <div className="bg-bgColor w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle">
                                <IdentificationIcon className="w-5"/>
                            </div>

                            <div className="">{salesCode || "- - - - -"}</div>

                            <div className="ml-auto">Sales Code</div>
                        </div>

                    </div>

                </div>

            </div>

            {/*Settings*/}
            <div className="w-2/3 bg-white m-3 rounded-lg">

            </div>

        </div>
    )
};


export default Profile;
